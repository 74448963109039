import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import styles from './HeaderA11y.module.scss'

export default function HeaderA11y() {
	const formatMessage = useFormatMessage()
	return (
		<div className={styles['header-accessibility-navigation']}>
			<a className={styles['skip-main']} href="#main">
				{formatMessage('skip-to-main')}
			</a>
		</div>
	)
}
