/* eslint-disable @typescript-eslint/no-use-before-define,max-classes-per-file */

import { ROOT_NAVIGATION_ID } from '../constants'
import type { NavigationTree } from '../navigation'

interface CategoryFilterInput {
	parentId: string
	deviceType: 'mobile' | 'desktop'
	sortBy: 'position' | 'name' | 'none'
	allowSeeThrough?: boolean
}

/**
 * Checks if the given navigation tree is a top-level category.
 * A top-level category is a navigation tree that has no parent.
 *
 * @param nav - The navigation tree to check.
 * @returns True if the navigation tree is a top-level category, false otherwise.
 */
function isTopLevelCategory(nav: NavigationTree): boolean {
	return nav.parent === ROOT_NAVIGATION_ID
}

/**
 * Checks if a navigation tree is a leaf category.
 * A leaf category is a category that does not have any sub-categories.
 *
 * @param nav - The navigation tree to check.
 * @returns `true` if the navigation tree is a leaf category, `false` otherwise.
 */
function isLeafCategory(nav: NavigationTree): boolean {
	return !nav.categories || nav.categories.length === 0
}

/**
 * Retrieves the parent category of a navigation item based on its ID.
 * If the ID is not provided or the parent category is not found, returns undefined.
 *
 * @param navList - The list of navigation items.
 * @param id - The ID of the navigation item.
 * @returns The parent category of the navigation item, or undefined if not found.
 */
function getParentCategory(navList: NavigationTree[], id?: string): NavigationTree | undefined {
	if (!id) {
		return undefined
	}

	const cat = getCategoryById(navList, id)
	if (cat?.parent) {
		return getCategoryById(navList, cat.parent)
	}
	return undefined
}

/**
 * Retrieves a navigation category by its ID from a given navigation list.
 * Efficiency: O(n)
 *
 * @param navList - The navigation list to search in.
 * @param id - The ID of the category to retrieve.
 * @returns The navigation category with the specified ID, or undefined if not found.
 */
function getCategoryById(navList: NavigationTree[], id: string): NavigationTree | undefined {
	if (id === ROOT_NAVIGATION_ID) {
		return {
			id: ROOT_NAVIGATION_ID,
			name: 'TopNav',
			url: '/',
			showMobile: true,
			showDesktop: true,
			expand: true,
			categories: navList,
		} satisfies NavigationTree
	}

	return navList.find((nav) => {
		if (nav.id === id) {
			return nav
		}
		if (nav.categories) {
			return getCategoryById(nav.categories, id)
		}
		return undefined
	})
}

/**
 * Retrieves the child categories from the given navigation list based on the provided filter.
 *
 * @param navList - The navigation list to search for child categories.
 * @param filter - The filter to apply when retrieving child categories.
 * @returns An array of child categories that match the filter criteria.
 */
function getChildCategories(navList: NavigationTree[], filter: CategoryFilterInput): NavigationTree[] {
	const childCategories =
		filter.parentId === ROOT_NAVIGATION_ID ? navList : getCategoryById(navList, filter.parentId)?.categories

	if (childCategories) {
		return childCategories
			.reduce<NavigationTree[]>(
				(acc, c) =>
					// add children to the accumulator if expand is true
					acc.concat(c, c.hideParent && filter.allowSeeThrough && c.categories ? c.categories : []),
				[],
			)
			.filter(
				(c) => (c.showMobile && filter.deviceType === 'mobile') || (c.showDesktop && filter.deviceType === 'desktop'),
			)
			.sort((a, b) => {
				if (filter.sortBy === 'position') {
					return (a.position || 0) - (b.position || 0)
				}
				if (filter.sortBy === 'name') {
					return a.name.localeCompare(b.name)
				}
				return 0
			})
	}
	return []
}

/**
 * For each category in the list, add a path property that is an array of all the category names from the top level to the current category.
 * This will be used by analytics as part of the tracker for when a category is clicked.  Note that we are
 * explicitly not doing this on the server so that we don't have to send the path to the client and increase the
 * server/client payload.
 * @param navList
 * @returns
 */
function hydrate(navList: NavigationTree[]): NavigationTree[] {
	const hydrateCategory = (nav: NavigationTree, path: string[]): NavigationTree => {
		const newPath = [...path, nav.name.toLocaleLowerCase()]
		if (nav.categories) {
			// eslint-disable-next-line no-param-reassign
			nav.categories = nav.categories.map((c) => hydrateCategory(c, newPath))
		}
		return { ...nav, path: newPath }
	}

	return navList.map((c) => hydrateCategory(c, ['TopNav']))
}

export default {
	isTopLevelCategory,
	isLeafCategory,
	getParentCategory,
	getCategoryById,
	getChildCategories,
	hydrate,
}
