'use client'

import React, { useCallback, useMemo, useState } from 'react'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import { useNavigationTracking } from '~/components/hooks/useNavigationTracking'
import type { IconColor } from '~/lib/icons'
import variables from '~/styles/variables.module.scss'
import styles from '~/components/layouts/NavbarLayout/Navigation/Navigation.module.scss'
import { useNavbar } from '../NavbarProvider'
import type { NavigationTree } from '~/lib/navigation'
import FlameIcon from '~/components/primitives/icons/FlameIcon'
import { LocaleLink } from '~/components/primitives/LocaleLink/LocaleLink'
import ArrowCloseDropDownIcon from '~/components/primitives/icons/ArrowCloseDropDownIcon'
import DesktopSubNav from './DesktopNav/DesktopSubNav'

export default function Navigation() {
	const { getCategoriesToDisplay } = useNavbar()
	const formatMessage = useFormatMessage()
	const [focus, setFocus] = useState(false)
	const fireTrackNavigation = useNavigationTracking()
	const [subNavOpen, onSetSubNavOpen] = useState<string>('')

	const navs = useMemo(() => getCategoriesToDisplay({ deviceType: 'desktop' }), [getCategoriesToDisplay])

	const closeNavigation = useCallback(() => onSetSubNavOpen?.(''), [onSetSubNavOpen])

	function handleLinkClick(event: React.MouseEvent<HTMLElement>): void {
		if ((event.target as HTMLElement).closest('a')) {
			closeNavigation()
		}
	}
	return (
		<>
			<nav className={styles.nav__menu} aria-label={formatMessage('main-navigation')}>
				{/* TODO: We may not want this to have any role. See MDN's documentation and their site as an example. */}
				<ul id="menu" role="menu" className={styles.nav__list} data-testid="nav-list">
					{navs.map((nav: NavigationTree) => (
						<li
							key={nav.id}
							onMouseEnter={() => {
								onSetSubNavOpen?.(nav.id)
								setFocus(false)
							}}
							onMouseLeave={() => onSetSubNavOpen?.('')}
							className={`${styles['nav__list-item']} ${styles.nav__link}`}
							data-testid="nav-list-item"
							role="menuitem"
						>
							<LocaleLink
								href={nav.url as string}
								onFocus={closeNavigation}
								onClick={() => {
									closeNavigation()
									fireTrackNavigation(nav)
								}}
								className={subNavOpen === nav.id ? 'is--active' : ''}
								data-testid={`nav-link-${nav.id}`}
							>
								{nav.name}
								{nav.flame && <FlameIcon title={nav.name || ''} />}
							</LocaleLink>
							{(nav?.categories?.length || 0) > 0 && (
								<button
									id={nav.id}
									aria-expanded={subNavOpen === nav.id}
									aria-controls={nav.id}
									aria-haspopup={(nav?.categories?.length || 0) > 0}
									aria-label={formatMessage('toggle-subnav', { id: nav.id })}
									type="button"
									className={styles.nav__btn}
									onClick={() =>
										subNavOpen === '' ? (onSetSubNavOpen?.(nav.id), setFocus(true)) : onSetSubNavOpen?.('')
									}
								>
									<ArrowCloseDropDownIcon size="XS" color={variables.white as IconColor} />
								</button>
							)}
							{nav.categories && nav.categories.length > 0 && (
								<div
									id={nav.id}
									role="menu"
									onClick={handleLinkClick}
									className={`${styles['nav__sub-menu']} ${subNavOpen === nav.id ? styles['nav__sub-menu--open'] : ''}`}
								>
									<DesktopSubNav nav={nav} />
									{focus ? (
										<div className={styles.nav__modal} />
									) : (
										<div onMouseEnter={closeNavigation} className={styles.nav__modal} />
									)}
								</div>
							)}
						</li>
					))}
				</ul>
			</nav>
		</>
	)
}
