import type { CSSProperties } from 'react'
import { useEffect, useRef } from 'react'

const deactivatedBodyStyle: Pick<CSSProperties, 'overflow' | 'position' | 'width'> = {
	overflow: 'hidden',
	position: 'fixed',
	width: '100%',
} as const

/*
- A hook that sets body overflow to 'hidden' when a slider/panel is open to prevent background scrolling
- localDependency is a flag passed in by the caller signaling the slider/panel is open
- once open, capturing of previous overflow property is kept in state, so when closed, previous setting is set
supporting multiple slider/panels open on top of eachother (i.e. refinements then search open at the same time)
*/

export function useDeactivateBodyScroll(localDependency: boolean): void {
	const previousDeactivatedBodyStyleRef = useRef<typeof deactivatedBodyStyle>({})
	const hasDeactivateBodyScroll = useRef(false)

	useEffect(() => {
		// Initialize previous body style
		previousDeactivatedBodyStyleRef.current = {
			overflow: document?.body.style.overflow,
			position: document?.body.style.position as 'static',
			width: document?.body.style.width,
		}
	}, [])

	useEffect(() => {
		const previousDeactivatedBodyStyle = previousDeactivatedBodyStyleRef.current
		if (localDependency) {
			// capture previous overflow property to preserve potential open slider/panel underneath
			previousDeactivatedBodyStyleRef.current = Object.keys(previousDeactivatedBodyStyle).reduce(
				(previousStyle, rule) => ({
					...previousStyle,
					[rule]: document.body.style[rule],
				}),
				{ ...previousDeactivatedBodyStyle },
			)

			Object.entries(deactivatedBodyStyle).forEach(([key, value]) => (document.body.style[key] = value))
			hasDeactivateBodyScroll.current = true
		} else {
			Object.entries(previousDeactivatedBodyStyle).forEach(([key, value]) => (document.body.style[key] = value))
			hasDeactivateBodyScroll.current = false
		}

		return () => {
			// Restore body style if unmounted in deactivated state
			if (hasDeactivateBodyScroll.current) {
				Object.entries(previousDeactivatedBodyStyle).forEach(([key, value]) => (document.body.style[key] = value))
			}
		}
	}, [localDependency])
}
