import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import styles from './MobileNav.module.scss'
import MobileNavItem from './MobileNavItem'
import type { NavigationTree } from '~/lib/navigation'
import { LocaleLink } from '~/components/primitives/LocaleLink/LocaleLink'

export type MobileSubNavProps = {
	navCategory: NavigationTree
	isOpen: boolean
	handleShopAllClick: (e: React.MouseEvent) => void
	setMobileMenu: (show: boolean) => void
	setActiveCategoryId: (id: string) => void
}

export default function MobileSubNav({
	navCategory,
	isOpen = false,
	handleShopAllClick,
	setMobileMenu,
	setActiveCategoryId,
}: MobileSubNavProps) {
	const formatMessage = useFormatMessage()

	if (!isOpen) return null

	return (
		<ul className={styles['mobile-sub-menu']}>
			{navCategory?.shopAllVisible && (
				<li className={styles['mobile-menu-item']}>
					<LocaleLink href={navCategory.url} name={navCategory.name} onClick={handleShopAllClick}>
						<span>
							{formatMessage('shop-all', {
								category: navCategory.name,
							})}
						</span>
					</LocaleLink>
				</li>
			)}
			{navCategory.categories
				?.filter((nav) => !nav.expand && nav.showMobile)
				.map((nav) => (
					<MobileNavItem
						key={nav.id}
						navCategory={nav}
						setMobileMenu={setMobileMenu}
						setActiveCategoryId={setActiveCategoryId}
					/>
				))}
		</ul>
	)
}
