import { useCallback } from 'react'
import FlameIcon from '~/components/primitives/icons/FlameIcon'

import { useNavigationTracking } from '~/components/hooks/useNavigationTracking'

import { LocaleLink } from '~/components/primitives/LocaleLink/LocaleLink'
import styles from './DesktopSubNav.module.scss'
import type { NavigationTree } from '~/lib/navigation'

export function DesktopSubCategory({ subcat }: { subcat: NavigationTree }) {
	const fireTrackNavigation = useNavigationTracking()
	const handleTrackNavigation = useCallback(() => fireTrackNavigation(subcat), [fireTrackNavigation, subcat])

	return (
		<li key={subcat.id}>
			<LocaleLink href={subcat.url} role="menuitem" onClick={handleTrackNavigation}>
				{subcat.name}
			</LocaleLink>
			{subcat.flame && (
				<div className={styles['nav-icon']}>
					<FlameIcon title={subcat?.name || ''} />
				</div>
			)}
		</li>
	)
}
