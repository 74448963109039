import { useLazyQuery } from '@apollo/client'
import type { AutocompleteResponse, Item } from '@constructor-io/constructorio-client-javascript/lib/types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ensureString } from 'types/strict-null-helpers'
import { useAuthModals } from '~/components/hooks/useAuthModals'
import { useDebouncedCallback } from '~/components/hooks/useDebouncedCallback'
import { useSession } from '~/components/providers/UaSessionProvider/UaSessionProvider'
import useInteractionOnce from '~/components/hooks/useInteractionOnce'
import { useLocale } from '~/components/hooks/useLocale'
import MobileSearch from '~/components/search/MobileSearch/MobileSearch'
import type { SearchSuggestionResultsProps } from '~/components/search/SearchSuggestionResults/SearchSuggestionResults'
import SearchInputForm from '~/components/shared/SearchInputForm/SearchInputForm'
import { GetSearchSuggestionsDocument } from '~/graphql/generated/uacapi/type-document-node'
import { getPublicConfig } from '~/lib/client-server/config'
import { getLocaleUrl } from '~/lib/i18n/urls'
import { createClientLogger } from '~/lib/logger'
import {
	VARIATIONS_MAP,
	addToRecentSearch,
	buildSearchUrl,
	categorySuggestionsArray,
	convertConstructorResponseToProducts,
	getConstructorLinkedSuggestions,
	isConstructorIOEnabled,
	productTileArray,
} from '~/lib/search'
import type { ClientProductTile } from '~/lib/types/product.interface'
import type { LinkedSuggestion, SearchSuggestions } from '~/lib/types/search-suggestions.interface'
import styles from '~/components/search/SearchBar/SearchBar.module.scss'
import { EnrollmentSource } from '~/lib/types/loyalty.interface'
import { getConstructorClientForBrowser } from '~/lib/client-only/search'
import useNavigation from '~/components/hooks/useNavigation'
import { useAnalytics } from '~/components/hooks/useAnalytics'

const logger = createClientLogger('search-bar')
let SearchSuggestionResults: React.FC<SearchSuggestionResultsProps>

const NUMBER_LINKED_SUGGESTIONS = 8
const MIN_NUMBER_LINKED_SUGGESTIONS = 5
const NUMBER_PRODUCTS = 4
const MINIMUM_QUERY_LENGTH = 3
const LOGIN_QS = '?rurl=1&login=true'
export const CIO_SUGGESTION_NAME = 'Search Suggestions'

export interface SearchProps {
	isMobile?: boolean
}

const SearchBar = ({ isMobile = false }: SearchProps) => {
	const locale = useLocale()
	const { showLoginModal } = useAuthModals()
	const { user } = useSession()
	const { analyticsManager } = useAnalytics()

	const [fallbackLinksSuggestions, setFallbackLinksSuggestions] = useState<LinkedSuggestion[] | null>(null)
	const [suggestedProducts, setSuggestedProducts] = useState<ClientProductTile[] | null>(null)
	const [totalSuggestedProducts, setTotalSuggestedProducts] = useState(0)
	const [suggestedLinks, setSuggestedLinks] = useState<LinkedSuggestion[] | null>(null)

	const [hasInput, setHasInput] = useState(false)
	const [searchQuery, setSearchQuery] = useState<string>('')
	const isLoggedIn = user?.isRegistered

	const [cioData, setCioData] = useState<AutocompleteResponse | null>(null)
	const [cioLoading, setCioLoading] = useState<boolean>(false)
	const [cioError, setCioError] = useState<boolean>(false)

	const clearSearchTerm = useCallback(() => {
		setSearchQuery('')
		setHasInput(false)
		setSuggestedProducts(null)
		setTotalSuggestedProducts(0)
		setSuggestedLinks(null)
		setCioData(null)
	}, [])

	const router = useNavigation({ onRouteChange: clearSearchTerm })

	const [getUACAPISuggestions, { data, loading, error }] = useLazyQuery(GetSearchSuggestionsDocument)

	const constructorIOEnabled = useMemo(() => isConstructorIOEnabled(locale), [locale])
	const constructorTimeout = getPublicConfig().cio.network_timeout

	const onSetSubNavOpen = useCallback((_id: string) => {
		// TODO: Implement subnav open
	}, [])

	const getSuggestionsFallback = useCallback(async () => {
		if (fallbackLinksSuggestions) {
			return
		}

		const cioClient = getConstructorClientForBrowser({ locale, user })
		await cioClient.recommendations
			.getRecommendations('best_sellers_search_suggestions', {
				numResults: NUMBER_LINKED_SUGGESTIONS,
				section: CIO_SUGGESTION_NAME,
			})
			.then((data) => {
				if (data?.response?.results?.length && setFallbackLinksSuggestions) {
					const loadedFallbackLinksSuggestions = getConstructorLinkedSuggestions(data.response.results as Item[])
					setFallbackLinksSuggestions(loadedFallbackLinksSuggestions)
				}
			})
	}, [fallbackLinksSuggestions, locale, user])

	const getSuggestions = useCallback(
		(query: string) => {
			const cioClient = getConstructorClientForBrowser({ locale, user })
			if (constructorIOEnabled && cioClient) {
				setCioLoading(true)
				// Now call Constructor
				cioClient.autocomplete
					.getAutocompleteResults(
						query,
						{
							variationsMap: VARIATIONS_MAP,
							resultsPerSection: {
								Products: NUMBER_PRODUCTS,
								[CIO_SUGGESTION_NAME]: NUMBER_LINKED_SUGGESTIONS,
								Categories: 0,
							},
						},
						{
							timeout: constructorTimeout,
						},
					)
					.then(async (constructorAutoSuggestResponseData) => {
						// if suggestions links are empty
						if (
							constructorAutoSuggestResponseData.sections[CIO_SUGGESTION_NAME].length < MIN_NUMBER_LINKED_SUGGESTIONS
						) {
							await getSuggestionsFallback()
						}
						return constructorAutoSuggestResponseData
					})
					.then((constructorAutoSuggestResponseData) => {
						setCioError(false)
						setCioData(constructorAutoSuggestResponseData)
					})
					.catch((reason) => {
						setCioError(true)
						// if Constructor failed, call UACAPI
						getUACAPISuggestions({
							variables: {
								q: query,
								numCategories: NUMBER_LINKED_SUGGESTIONS,
								numProducts: NUMBER_PRODUCTS,
							},
						})
						if (reason?.code === 20) {
							// timed out, we'll manage because of getUACAPISuggestions
							logger.info('[Constructor.io] Search timed out, failing over to backup search provider')
						} else {
							// Someone should fix anything else
							logger.error(`[Constructor.io] ${reason}`)
						}
					})
					.finally(() => {
						setCioLoading(false)
					})
			} else {
				getUACAPISuggestions({
					variables: {
						q: query,
						numCategories: NUMBER_LINKED_SUGGESTIONS,
						numProducts: NUMBER_PRODUCTS,
					},
				})
			}
		},
		[locale, user, constructorIOEnabled, constructorTimeout, getSuggestionsFallback, getUACAPISuggestions],
	)

	useEffect(() => {
		// Should we be updating the rendering?
		const isValidCioData: boolean | Item[] | undefined = !cioError && !cioLoading && cioData?.sections.Products
		const isValidUACAPIData: boolean | { __typename?: 'SuggestionResult' } | null | undefined =
			!isValidCioData && !error && !loading && !cioLoading && data?.searchSuggestions
		if ((isValidCioData || isValidUACAPIData) && user) {
			const suggestionData: SearchSuggestions = {
				links: [],
				products: [],
			}
			// Determine which data should we use (assuming we prefer cioData)
			if (cioData && isValidCioData) {
				// use Constructor response to hydrate rendering data
				const links = getConstructorLinkedSuggestions(cioData.sections[CIO_SUGGESTION_NAME])
				suggestionData.links = [
					...(links || []),
					...((links?.length || 0) < MIN_NUMBER_LINKED_SUGGESTIONS && fallbackLinksSuggestions
						? fallbackLinksSuggestions
						: []),
				].slice(0, 8)
				suggestionData.products = convertConstructorResponseToProducts({
					products: cioData.sections.Products,
					userDetails: user || undefined,
					isSuggestion: true,
				})
			} else if (data?.searchSuggestions && isValidUACAPIData) {
				// use UACAPI response to hydrate rendering data
				const suggestedCategoriesArray = categorySuggestionsArray(data.searchSuggestions.categories.edges)
				const suggestedProductsArray = productTileArray(data.searchSuggestions.products.edges)
				suggestionData.links = suggestedCategoriesArray.categorySuggestions
				suggestionData.products = suggestedProductsArray.products
			}

			setSuggestedLinks(suggestionData.links)
			setSuggestedProducts(suggestionData.products)
			setTotalSuggestedProducts(suggestionData.products?.length)
		}
		// Added searchQuery to the list of dependencies. Because when you clear query with clearSearchTerm and re-enter searchQuery with the same query after,
		// the data.searchSuggestions is not updated, and the suggestedProducts and suggestedLinks arrays remain empty
	}, [cioData, cioLoading, cioError, data, loading, error, searchQuery, user, fallbackLinksSuggestions])

	const debouncedGetSuggestions = useDebouncedCallback((query: string) => {
		getSuggestions(query)
	}, 250)

	const onSearchTextChanged = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const query = ensureString(e.target.value).trim()
		setSearchQuery(query)
		if (e.target.value.length >= MINIMUM_QUERY_LENGTH) {
			setHasInput(true)
			debouncedGetSuggestions(query)
		}
	}

	const handleSearchRedirect = async () => {
		if (searchQuery.length > 0) {
			// Test whether a redirect occurs or if a search request needs to be made
			// NOTE: Use the router to ensure that page transitions handler	work properly.
			let shouldExecuteSearchRequest = true

			if (constructorIOEnabled) {
				try {
					const cioClient = getConstructorClientForBrowser({ locale, user })

					const searchResponse = await cioClient.search.getSearchResults(searchQuery, undefined, {
						timeout: constructorTimeout,
					})
					analyticsManager.fireCioSearchEvent(searchResponse)
					const redirectUrl = searchResponse?.response?.redirect?.data?.url

					if (redirectUrl) {
						shouldExecuteSearchRequest = false
						const isLoginRedirect = redirectUrl.endsWith(LOGIN_QS)

						// There are three types of redirects:
						// 1. Login --> user is logged in, redirect to account page
						// 2. Login --> user is logged out, shows login modal
						// 3. Non-login --> redirection to assigned url
						if (isLoginRedirect) {
							if (isLoggedIn) {
								const localizedAccountUrl = getLocaleUrl('/account/', locale)
								clearSearchTerm()
								router.push(localizedAccountUrl)
							} else {
								showLoginModal(undefined, EnrollmentSource.ECOMMLOGINSEARCHBAR)
								clearSearchTerm()
							}
						} else {
							clearSearchTerm()
							router.push(redirectUrl)
						}
					}
				} catch (e) {
					logger.error(`Message: ${e}, Query: '${searchQuery}', Source: ConstructorIO`)
				}
			}

			// If no redirection, route users to search page
			if (shouldExecuteSearchRequest) {
				addToRecentSearch(searchQuery)
				const searchUrl = buildSearchUrl(searchQuery)
				const localeUrl = getLocaleUrl(searchUrl, locale)
				clearSearchTerm()
				router.push(localeUrl)
			}
		}
	}

	const isSearchResultsFlyoutVisible = hasInput && (loading || cioLoading || suggestedProducts)

	const searchSuggestionResults = isSearchResultsFlyoutVisible ? (
		<SearchSuggestionResults
			searchQuery={searchQuery}
			productSuggestions={suggestedProducts}
			totalProductSuggestions={totalSuggestedProducts}
			linkedSuggestions={suggestedLinks || []}
			loading={loading || cioLoading}
			// This is required for when the same product or color variant is clicked within the results.
			// onRouteChange won't be able to pick it up
			handleSuggestionClick={clearSearchTerm}
		/>
	) : null

	useInteractionOnce(async () => {
		SearchSuggestionResults = (await import('~/components/search/SearchSuggestionResults/SearchSuggestionResults'))
			.default
	})

	return (
		<>
			{isMobile && (
				<MobileSearch
					searchTerm={searchQuery}
					onChange={onSearchTextChanged}
					onSearchRedirect={handleSearchRedirect}
					onClearSearch={clearSearchTerm}
					onSetSubNavOpen={onSetSubNavOpen}
				>
					{searchSuggestionResults}
				</MobileSearch>
			)}

			<div className={styles['dt-search-bar']} data-testid="search-bar">
				<SearchInputForm
					onSetSubNavOpen={onSetSubNavOpen}
					searchQuery={searchQuery}
					onSearchTextChanged={onSearchTextChanged}
					handleSearchRedirect={handleSearchRedirect}
					onClearSearchTerm={clearSearchTerm}
				/>
			</div>

			{!isMobile && (
				<div className={`${styles['search-panel']} ${isSearchResultsFlyoutVisible ? styles['search-panel-open'] : ''}`}>
					<div className={styles['search-flyout']} id="search-results">
						{searchSuggestionResults}
					</div>
				</div>
			)}
		</>
	)
}

export default SearchBar
