import FavoriteBorderOutlined from '~/components/primitives/icons/FavoriteOutlinedIcon'
import styles from './Header.module.scss'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import { LocaleLink } from '~/components/primitives/LocaleLink/LocaleLink'

export function FavoritesButton() {
	const formatMessage = useFormatMessage()

	return (
		<LocaleLink
			href={'/saved-items'}
			aria-label={formatMessage('save-for-later')}
			className={`${styles['nav-icon-button']} ${styles['nav-icon-favorites']}`}
		>
			<FavoriteBorderOutlined size="SM" />
		</LocaleLink>
	)
}
