import { type PropsWithChildren, type ReactElement, memo } from 'react'
import styles from './MobileNav.module.scss'
import clsx from 'clsx'
import { LocaleLink } from '~/components/primitives/LocaleLink/LocaleLink'

interface MobileNavProps {
	children: ReactElement<NavItemElement> | Array<ReactElement<NavItemElement>>
}

/**
 * This component enforces the bottom, mobile, navigational links for the app.
 *
 * The children of the `MobileNav` are `NavItem` components which will help structure the child elements and keep the layout consistent. All
 * link actions need to be composed using onClick handlers and calling `router.push()` to perform the navigation.
 */
export const MobileNav = ({ children }: MobileNavProps) => {
	return (
		<section className={styles['mobile-nav']}>
			<div>{children}</div>
		</section>
	)
}

type NavItemElement = HTMLDivElement

interface NavItemProps {
	label?: string
	className?: string
	onClick?: () => void
	href?: string
	target?: string
}

export const NavItem = memo(function NavItem({
	children,
	label,
	onClick,
	href,
	target,
	className,
}: PropsWithChildren<NavItemProps>): ReactElement<NavItemElement> {
	const RootElementBase = ({ children, ...attrs }) => {
		if (href) {
			return (
				<LocaleLink href={href} target={target} onClick={onClick} {...attrs}>
					{children}
				</LocaleLink>
			)
		}
		return (
			<button type="button" onClick={onClick} {...attrs}>
				{children}
			</button>
		)
	}

	const RootElement = memo(RootElementBase)

	return (
		<RootElement tabIndex={0} className={clsx(styles['mobile-nav-item'], className?.trim())}>
			{children}
			{label && <label>{label}</label>}
		</RootElement>
	)
})
