import clsx from 'clsx'
import { useState } from 'react'
import { useAnalytics } from '~/components/hooks/useAnalytics'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import { useLocale } from '~/components/hooks/useLocale'
import styles from './MobileNav.module.scss'
import MobileSubNav from './MobileSubNav'
import { ROOT_NAVIGATION_ID } from '~/lib/constants'
import Navigation from '~/lib/client-only/navigation'
import type { NavigationTree } from '~/lib/navigation'
import { LocaleLink } from '~/components/primitives/LocaleLink/LocaleLink'
import FlameIcon from '~/components/primitives/icons/FlameIcon'
import { IconButton } from '~/components/primitives/Button/IconButton'
import ArrowIcon from '~/components/primitives/icons/ArrowIcon'

export type ListItemProps = {
	setMobileMenu: (show: boolean) => void
	setActiveCategoryId: (id: string) => void
	isExpanded?: boolean
	isRoot?: boolean
	navCategory: NavigationTree
}

export default function MobileNavItem({
	navCategory,
	setMobileMenu,
	setActiveCategoryId,
	isExpanded = false,
	isRoot = false,
}: ListItemProps) {
	const locale = useLocale()
	const formatMessage = useFormatMessage()
	const { analyticsManager } = useAnalytics()
	const [subnavOpen, onSetSubNavOpen] = useState(false)

	const handleNavClick = () => {
		if (isRoot) {
			setActiveCategoryId(navCategory.id)
		} else {
			onSetSubNavOpen(!subnavOpen)
		}
	}

	const handleTerminalNavClick = () => {
		analyticsManager.trackNavigation(navCategory, locale)
		setMobileMenu(false)
		setActiveCategoryId(ROOT_NAVIGATION_ID)
	}

	return (
		<li
			key={navCategory.id}
			className={clsx(
				styles['mobile-menu-item'],
				isExpanded && styles['is-expanded'],
				isRoot && styles['is-root'],
				subnavOpen && styles['subnav-open'],
			)}
		>
			{Navigation.isLeafCategory(navCategory) ? (
				<LocaleLink
					href={navCategory.url}
					role="mobile-menu-item"
					aria-label={navCategory.name}
					onClick={handleTerminalNavClick}
				>
					<span>{navCategory.name}</span>
					{navCategory.flame && (
						<div className={styles['nav-icon']}>
							<FlameIcon title={navCategory.name} />
						</div>
					)}
				</LocaleLink>
			) : (
				<a role="mobile-menu-item" aria-label={navCategory.name} onClick={handleNavClick}>
					<span>{navCategory.name}</span>
					{navCategory.flame && (
						<div className={styles['nav-icon']}>
							<FlameIcon title={navCategory.name} />
						</div>
					)}
					<IconButton
						className={clsx(styles['mobile-forward-arrow'], !isRoot && styles['mobile-down-arrow'])}
						label={formatMessage('select-category', { category: navCategory.name })}
					>
						<ArrowIcon size="MD" />
					</IconButton>
				</a>
			)}
			{!Navigation.isLeafCategory(navCategory) && (
				<MobileSubNav
					navCategory={navCategory}
					isOpen={subnavOpen}
					handleShopAllClick={handleTerminalNavClick}
					setMobileMenu={setMobileMenu}
					setActiveCategoryId={setActiveCategoryId}
				/>
			)}
		</li>
	)
}
