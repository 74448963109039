import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styles from './AnimatedCounter.module.scss'
import clsx from 'clsx'
import type { UAThemeCSSProperties } from '~/lib/types/theme.interface'

interface AnimatedCounterProps {
	value: number
	className?: string
	animationTime?: number
	animationDisabled?: boolean
	startAnimation?: boolean
	animationStyle?: 'simple' | 'complex'
}

function AnimatedCounter({
	value = 0,
	className,
	animationTime = 2000,
	animationDisabled = false,
	startAnimation = true,
	animationStyle = 'simple',
}: AnimatedCounterProps) {
	const valueArr = useMemo(() => value.toString().split(''), [value])
	const [count, setCount] = useState(0)
	const animationFrameRef = useRef<ReturnType<typeof requestAnimationFrame>>()
	const previousTimeRef = useRef<number>()
	const animationDuration = useRef<number>(0)

	const showAnimation = useMemo(
		() => !animationDisabled && animationTime > 0 && value > 0,
		[animationDisabled, animationTime, value],
	)

	const parsedNumbers = useMemo(() => {
		if (!showAnimation) return null
		const tenNumbers: number[] = [9, 8, 7, 6, 5, 4, 3, 2, 1, 0]
		return valueArr.map((item, index, arr) => {
			const number = parseInt(item, 10)
			let column: Array<number | number[]> = []

			for (let i = 0; i < index; i++) {
				column.push(tenNumbers)
			}

			for (let i = 0; i <= number; i++) {
				column.unshift(i)
			}

			column = column.flat()

			const delay = (animationTime / arr.length) * (arr.length - index - 1)
			const duration = animationTime - delay
			const finalPosition = 100 - 100 / column.length
			const styles: UAThemeCSSProperties = {
				'--anim-final-position': `${finalPosition}%`,
				'--anim-delay': `${(delay / 4) * 3}ms`,
				'--anim-duration': `${duration}ms`,
			}
			return { column, styles }
		})
	}, [showAnimation, animationTime, valueArr])

	const animationStyles: UAThemeCSSProperties = useMemo(
		() => ({
			'--anim-time': `${animationTime}ms`,
			'--anim-columns': valueArr.length,
		}),
		[animationTime, valueArr.length],
	)

	const easeInOutSine = (x: number) => -(Math.cos(Math.PI * x) - 1) / 2

	const animate = useCallback<FrameRequestCallback>(
		(time) => {
			if (animationDuration.current > animationTime && animationFrameRef.current) {
				cancelAnimationFrame(animationFrameRef.current)
				setCount(value)
				return
			}

			if (previousTimeRef.current) {
				animationDuration.current += time - previousTimeRef.current
				setCount(Math.floor(easeInOutSine(animationDuration.current / animationTime) * value))
			}

			previousTimeRef.current = time
			animationFrameRef.current = requestAnimationFrame(animate)
		},
		[animationTime, value],
	)

	useEffect(() => {
		if (animationStyle === 'simple' && showAnimation && startAnimation && !animationFrameRef.current) {
			animationFrameRef.current = requestAnimationFrame(animate)
		}
		return () => {
			if (animationFrameRef.current) {
				cancelAnimationFrame(animationFrameRef.current)
				animationFrameRef.current = undefined
				previousTimeRef.current = undefined
				animationDuration.current = 0
				setCount(0)
			}
		}
	}, [animate, animationStyle, showAnimation, startAnimation])

	return (
		<div
			className={clsx(
				styles['counter-container'],
				{ [styles['hold-animation']]: !startAnimation },
				styles[`animation-${animationStyle}`],
				className,
			)}
		>
			{animationStyle === 'complex' ? (
				<>
					{showAnimation && (
						<div className={styles['animated-counter']} style={animationStyles}>
							{parsedNumbers?.map((numbersObj, index) => (
								<div key={index} className={styles['animated-column']} style={numbersObj.styles}>
									{numbersObj.column.map((line, i) => (
										<div key={`${index}${i}`}>{line}</div>
									))}
								</div>
							))}
						</div>
					)}
					<div className={styles['static-value']}>
						{valueArr.map((number, index) => (
							<div key={index} className={styles['static-column']}>
								<span>{number}</span>
							</div>
						))}
					</div>
				</>
			) : (
				<div className={styles['animated-counter']}>{showAnimation ? count : value}</div>
			)}
		</div>
	)
}

export default AnimatedCounter
