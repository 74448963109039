import React, { memo, useCallback, useMemo } from 'react'
import clsx from 'clsx'

import { useFeatureFlags } from '~/components/providers/CommerceConfigurationProvider/CommerceConfigurationProvider'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import { useSession } from '~/components/providers/UaSessionProvider/UaSessionProvider'
import { useLocale } from '~/components/hooks/useLocale'
import { LocaleLink } from '~/components/primitives/LocaleLink/LocaleLink'
import { getPublicConfig } from '~/lib/client-server/config'
import { DropMenuOption } from '~/components/primitives/DropMenu/DropMenu'

import styles from './AccountNavLinks.module.scss'
import { accountNavData } from './account-nav-data'
import ArrowIcon from '~/components/primitives/icons/ArrowIcon'

interface Props {
	isChildOfDropMenu?: boolean
	hideLogoutLink?: boolean
	hideRewardsLink?: boolean
	setMobileMenu?: (bool: boolean) => void
	onLinkClick?: () => void
	currentPath: string
}

const AccountNavLinks = ({
	hideLogoutLink,
	hideRewardsLink = false,
	setMobileMenu,
	onLinkClick,
	currentPath,
}: Props) => {
	const { user: customer, logout } = useSession()
	const formatMessage = useFormatMessage()
	const featureFlags = useFeatureFlags()
	const locale = useLocale()
	const isCurrentPath = useCallback((url: string) => url === currentPath, [currentPath])

	// Filter the routes based on the loyalty status & preferred sizes config
	const filteredNavData = useMemo(() => {
		return accountNavData
			.filter(
				(route) =>
					!route.filter ||
					(route.filter === 'loyaltyStatus' &&
						featureFlags?.isLoyaltyEnabled &&
						customer?.isLoyalty &&
						!hideRewardsLink) ||
					(route.filter === 'registeredNoLoyalty' &&
						featureFlags?.isLoyaltyEnabled &&
						!featureFlags?.isLoyaltyPilotEnabled &&
						customer?.isRegistered &&
						!customer.isLoyalty) ||
					(route.filter === 'preferredSizes' && getPublicConfig().preferredsizes.locales.includes(locale)),
			)
			.map((route) => ({
				...route,
				isCurrentPath: isCurrentPath(route.route),
			}))
	}, [customer, featureFlags, isCurrentPath, locale, hideRewardsLink])

	const handleLogout = useCallback(async () => {
		logout()
		if (setMobileMenu) {
			setMobileMenu(false)
		}
	}, [setMobileMenu, logout])

	return (
		<>
			<DropMenuOption key="my-account-header" className={styles['nav-menu-item-header']}>
				<span>{formatMessage('my-account')}</span>
			</DropMenuOption>
			{filteredNavData.map(({ route, text, isCurrentPath }) => (
				<DropMenuOption
					className={clsx(styles['nav-menu-item'], {
						[styles['nav-menu-item-active']]: isCurrentPath,
					})}
					key={text}
				>
					<LocaleLink onClick={onLinkClick} href={route}>
						{formatMessage(text)}
					</LocaleLink>
					<span className={styles['mobile-menu-open']}>
						<ArrowIcon />
					</span>
				</DropMenuOption>
			))}

			{!hideLogoutLink && (
				<DropMenuOption className={clsx(styles['nav-menu-item'], styles['logout-btn'])}>
					<button type="button" className={styles.logout} onClick={handleLogout}>
						{formatMessage('logout')}
					</button>
				</DropMenuOption>
			)}
		</>
	)
}

export default memo(AccountNavLinks)
