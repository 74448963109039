import { LocaleLink } from '~/components/primitives/LocaleLink/LocaleLink'
import styles from '~/components/layouts/NavbarLayout/Header/Header.module.scss'
import React, { useMemo, useState } from 'react'
import { ensureBoolean, ensureNumber } from '~/types/strict-null-helpers'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import { useCart } from '~/components/providers/CartProvider/CartProvider'
import { useLayoutMeasurement } from '~/components/hooks/useLayoutMeasurement'
import dynamic from 'next/dynamic'
import ShoppingBagOutlined from '~/components/primitives/icons/ShoppingBagOutlined'

const MiniCartMenuModal = dynamic(
	() => import('~/components/layouts/NavbarLayout/Header/MiniCartMenu/MiniCartMenuModal'),
	{ ssr: false },
)

export interface CartButtonProps {
	minified?: boolean
}

export const CartButton = ({ minified }: CartButtonProps) => {
	const formatMessage = useFormatMessage()
	const { isMobile } = useLayoutMeasurement()
	const [cartOverlay, setCartOverlay] = useState(false)
	const [isCartVisible, setIsCartVisible] = useState(true)
	const { cart: cartData } = useCart()

	const isMiniCartMenuOpen = Boolean(cartData) && !isMobile && ensureNumber(cartData?.totals) > 0

	const handleMouseEnter = () => {
		// If checkout page (minified), do not show overlay
		if (minified) return
		setCartOverlay(true)
	}
	const handleMouseLeave = () => {
		setCartOverlay(false)
	}
	const hideMinicart = () => {
		setIsCartVisible(false)
		setCartOverlay(false)
		setTimeout(() => setIsCartVisible(true), 1)
	}

	const cartText = useMemo(() => {
		if (cartData?.totals === undefined) return formatMessage('add-to-bag')
		return Number(cartData?.totals) >= 1
			? formatMessage('minicart-items-in-cart', { cartTotal: cartData?.totals })
			: formatMessage('cart-empty')
	}, [cartData, formatMessage])

	return (
		<>
			<div className={styles['cart-wrapper']} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
				<LocaleLink
					href={'/cart'}
					aria-label={cartText}
					className={`${styles['nav-icon-button']} ${styles['nav-icon-bag']}`}
					id="shopping-bag"
					data-testid="shopping-bag"
				>
					<ShoppingBagOutlined size="SM" />
					{ensureBoolean(!!cartData?.totals) && (
						<span className={cartData?.totals ? styles['nav__cart-count'] : styles.nav__hidden} aria-hidden="true">
							{cartData?.totals}
						</span>
					)}
				</LocaleLink>
				{isMiniCartMenuOpen && (
					<MiniCartMenuModal
						className={isCartVisible ? styles['mini-cart'] : styles['hide-mini-cart']}
						onCartCheckoutClick={hideMinicart}
					/>
				)}
			</div>
			{isMiniCartMenuOpen && cartOverlay && <div className={styles.nav__modal} />}
		</>
	)
}
