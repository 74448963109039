import type { IconProps } from '~/lib/icons'
import { getEnhancedIconProps } from '~/lib/icons'

export default function MenuOutlinedIcon(props: IconProps) {
	return (
		<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...getEnhancedIconProps(props)}>
			<mask id="0dfxpkv3wa" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
				<path fill="#fff" d="M0 0h16v16H0z" />
			</mask>
			<g mask="url(#0dfxpkv3wa)" fill="currentColor">
				<path d="M.5 2.5a.5.5 0 0 0 0 1v-1zm15 1a.5.5 0 0 0 0-1v1zm-15 4a.5.5 0 0 0 0 1v-1zm15 1a.5.5 0 0 0 0-1v1zm-15 4a.5.5 0 0 0 0 1v-1zm15 1a.5.5 0 0 0 0-1v1zm-15-10h15v-1H.5v1zm0 5h15v-1H.5v1zm0 5h15v-1H.5v1z" />
			</g>
		</svg>
	)
}
