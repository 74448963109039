import React from 'react'
import type { ApolloClient } from '@apollo/client'
import {
	GetNavigationDataDocument,
	GetNavigationDataTopLevelDocument,
} from '~/graphql/generated/uacapi/type-document-node'
import logger from '~/lib/logger'
import { convertCategoriesToNavigationTree, type NavigationTree } from '../navigation'

/**
 * Fetches the navigation data from the server and converts it to a tree structure that is lighter weight than the full category data.
 * It will also filter out categories that are not shown in the menu.
 */
export const getNavigationData = React.cache(
	async (client: ApolloClient<object>, shallow: boolean): Promise<NavigationTree[] | null> => {
		const { data, errors } = await client.query({
			query: shallow ? GetNavigationDataTopLevelDocument : GetNavigationDataDocument,
		})
		if (errors) {
			logger.error(errors)
			return null
		}
		return convertCategoriesToNavigationTree(data.navData)
	},
)
