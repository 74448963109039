'use client'

import { Suspense, useMemo } from 'react'
import { useSession } from '~/components/providers/UaSessionProvider/UaSessionProvider'
import { headerLinks } from '~/components/layouts/NavbarLayout/Header/header-data'
import { CountrySelector } from '~/components/layouts/NavbarLayout/Header/HeaderUtility/CountrySelector/CountrySelector'
import styles from '~/components/layouts/NavbarLayout/Header/HeaderUtility/HeaderUtility.module.scss'
import LanguageSelector from '~/components/layouts/NavbarLayout/Header/HeaderUtility/LanguageSelector/LanguageSelector'
import { UserDropMenu } from '~/components/layouts/NavbarLayout/Header/HeaderUtility/UserDropMenu/UserDropMenu'

import { useAuthModals } from '~/components/hooks/useAuthModals'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import { useLocale } from '~/components/hooks/useLocale'
import { HelpCenterLink } from '~/components/shared/HelpCenterLink/HelpCenterLink'
import { EnrollmentSource } from '~/lib/types/loyalty.interface'
import { buildCountrySitesMap } from '~/lib/i18n/locale'

export interface Props {
	currentPath: string
	countryOverride?: string
}

export default function HeaderUtility(props: Props) {
	const formatMessage = useFormatMessage()
	const locale = useLocale()
	const { user: customer } = useSession()
	const countrySites = useMemo(() => buildCountrySitesMap(), [])
	const { showLoginModal, showRegisterModal } = useAuthModals()

	const myAccountButtonLabel = customer?.firstName
		? `${formatMessage('welcome')} ${customer?.firstName}`
		: `${formatMessage('my-account')}`

	return (
		<>
			<div className={styles['header-utility']} data-testid="header-utility">
				<div className={styles['header-utility-item']}>
					<HelpCenterLink
						href={headerLinks[locale].needHelp.url}
						target="_blank"
						rel="noreferrer"
						className={styles['header-account-link']}
						data-testid="login-pop-up-register-btn"
						source="header"
					>
						{formatMessage(headerLinks[locale].needHelp.id)}
					</HelpCenterLink>
				</div>

				<div className={styles['header-utility-item']}>
					<Suspense>
						<CountrySelector countrySites={countrySites} />
					</Suspense>
				</div>

				<div className={styles['header-utility-item']}>
					<LanguageSelector currentPath={props.currentPath} />
				</div>

				<div
					className={`${styles['header-utility-item']} ${styles['header-account']} bfx-remove-element`}
					data-testid="header-account"
				>
					{customer?.isRegistered && (
						<div className={styles['header-utility-item']}>
							<UserDropMenu currentPath={props.currentPath} buttonLabel={myAccountButtonLabel} />
						</div>
					)}
					{customer?.isGuest && (
						<>
							<button
								type="button"
								className={`${styles['header-account-link']} ${styles['header-account-link-register']}`}
								aria-controls="register"
								onClick={() => showRegisterModal(undefined, EnrollmentSource.ECOMMREGISTRATION)}
							>
								{formatMessage('register')}
							</button>
							<span className={styles['header-utility-item-spacer']}>|</span>
							<button
								type="button"
								className={styles['header-account-link']}
								aria-controls="login"
								data-testid="login-button"
								onClick={() => showLoginModal(undefined, EnrollmentSource.ECOMMLOGIN)}
							>
								{formatMessage('login')}
							</button>
						</>
					)}
				</div>
			</div>
		</>
	)
}
