import styles from '~/components/layouts/NavbarLayout/Header/Header.module.scss'
import { LocaleLink } from '~/components/primitives/LocaleLink/LocaleLink'
import Image from '~/components/primitives/Image'
import React from 'react'

export const UaLogoButton = ({ isMobile = false }: { isMobile?: boolean }) => {
	return (
		<div className={styles['logo-wrapper']}>
			<LocaleLink href="/" className={styles['logo-link']}>
				<Image
					src="https://www.underarmour.com/on/demandware.static/Sites-US-Site/-/default/dwe3cdd709/images/logo.svg"
					title="Under Armour"
					data-testid="ua-logo"
					alt="Under Armour Logo"
					width={isMobile ? 32 : 42}
					height={isMobile ? 18 : 24}
					className="logo"
					priority
				/>
			</LocaleLink>
		</div>
	)
}
