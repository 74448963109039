import PersonOutlinedIcon from '~/components/primitives/icons/PersonOutlinedIcon'
import { EnrollmentSource } from '~/lib/types/loyalty.interface'
import styles from '~/components/layouts/NavbarLayout/Navigation/Navigation.module.scss'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import { useSession } from '~/components/providers/UaSessionProvider/UaSessionProvider'
import { useAuthModals } from '~/components/hooks/useAuthModals'
import { useLocale } from '~/components/hooks/useLocale'
import { getLocaleUrl } from '~/lib/i18n/urls'
import variables from '~/styles/variables.module.scss'
import type { IconColor } from '~/lib/icons'

export function ProfileButton() {
	const locale = useLocale()
	const { user } = useSession()
	const formatMessage = useFormatMessage()
	const { showLoginModal } = useAuthModals()

	const onClickProfileButton = () => {
		if (user?.isGuest) {
			showLoginModal(undefined, EnrollmentSource.ECOMMLOGIN)
		} else {
			window.location.href = getLocaleUrl('/account', locale)
		}
	}

	return (
		<button
			type="button"
			className={styles.nav__login}
			aria-label={formatMessage('login')}
			onClick={onClickProfileButton}
		>
			<PersonOutlinedIcon color={variables.white as IconColor} />
		</button>
	)
}
