import { useAnalytics } from '~/components/hooks/useAnalytics'
import { useLocale } from '~/components/hooks/useLocale'
import type { NavigationTree } from '~/lib/navigation'
import type { DataLayerInterface } from '~/lib/types/analytics.interface'

export function useNavigationTracking() {
	const locale = useLocale()
	const { analyticsManager } = useAnalytics()
	return (cat: NavigationTree, data?: Partial<DataLayerInterface>) => {
		analyticsManager.trackNavigation(cat, locale, data)
	}
}
