import { useCallback } from 'react'
import { useNavigationTracking } from '~/components/hooks/useNavigationTracking'
import { LocaleLink } from '~/components/primitives/LocaleLink/LocaleLink'
import { DesktopSubCategory } from './DesktopSubCategory'
import styles from './DesktopSubNav.module.scss'
import type { NavigationTree } from '~/lib/navigation'

export function DesktopCategory({ maincat }: { maincat: NavigationTree }) {
	const fireTrackNavigation = useNavigationTracking()
	const handleCategoryClick = useCallback(() => fireTrackNavigation(maincat), [fireTrackNavigation, maincat])

	const CatLink = (
		<LocaleLink
			href={maincat.url as string}
			className={styles['sub-menu-category-title']}
			role="heading"
			aria-level={2}
			onClick={handleCategoryClick}
		>
			{maincat.name}
		</LocaleLink>
	)

	return (
		<li key={maincat.id} className={styles['sub-menu-categories']}>
			{maincat.disableClick ? <h2 className={styles['sub-menu-category-title']}>{maincat.name}</h2> : CatLink}
			<ul className={styles['sub-menu-category-grouping']}>
				{maincat.categories &&
					maincat.categories.filter((sc) => sc.showDesktop).map((sc) => <DesktopSubCategory key={sc.id} subcat={sc} />)}
			</ul>
		</li>
	)
}
