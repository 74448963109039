import { createContext, useContext, useEffect, useState, useCallback } from 'react'
import { useLocale } from '~/components/hooks/useLocale'
import Navigation from '~/lib/client-only/navigation'
import { standAloneApolloClient } from '~/lib/client-server/uacapi-client'
import { getNavigationData } from '~/lib/server/navigation'
import { noop } from '~/lib/utils'
import { ROOT_NAVIGATION_ID } from '~/lib/constants'
import type { NavigationTree } from '~/lib/navigation'

interface GetCategoriesToDisplayArgs {
	activeCategory?: NavigationTree
	deviceType: 'desktop' | 'mobile'
}
interface NavbarContext {
	showMobileMenu: boolean
	setShowMobileMenu: (show: boolean) => void
	navList: NavigationTree[]
	getCategoriesToDisplay: ({ activeCategory, deviceType }: GetCategoriesToDisplayArgs) => NavigationTree[]
}

const navbarProviderContext = createContext<NavbarContext>({
	showMobileMenu: false,
	setShowMobileMenu: (_show: boolean) => noop(),
	navList: [],
	getCategoriesToDisplay: () => {
		return []
	},
})

export function useNavbar(): NavbarContext {
	return useContext(navbarProviderContext)
}

interface NavbarProviderProps {
	defaultNavList: NavigationTree[]
}

/**
 * The NavbarProvider component is a context provider that provides the current navigation list and mobile menu state to its children.
 * This is necessary because the navigation is rendered in two places - the desktop navigation and the mobile navigation.  This shared
 * provider provides the following functionality:
 *
 * 1. Share the current mobile navbar state with siblings and parents in the hierarchy.  This is important because it allows
 *   	the mobile navigation to be toggled from the hamburger menu which is rendered in the header.
 * 2. Update the current navigation list when the page first loads to pull the full navbar data from the server.  Initially, when the
 *  	page loads, the navigation list is "shallow".  This provider fetches the navigation data from the server and updates the context with
 * 		the full navigation list.
 *
 * You can prepopulate the navigation list by passing in a defaultNavList prop.  This is useful for server-side rendering where the navigation
 * data is already available.  It's recommending that this initial list be kept minimal to reduce the initial page load time and bandwidth used
 * on the server.  We have found the full navigation list to be extremely large.
 * @returns
 */
export function NavbarProvider({
	children,
	defaultNavList,
}: React.PropsWithChildren<NavbarProviderProps>): React.ReactElement {
	const locale = useLocale()
	const [showMobileMenu, setShowMobileMenu] = useState(false)
	const [navList, setNavList] = useState<NavigationTree[]>(defaultNavList)

	useEffect(() => {
		getNavigationData(standAloneApolloClient(locale), false).then((data) => {
			setNavList(data ? Navigation.hydrate(data) : [])
		})
	}, [locale])

	const isRootCategory = (category: NavigationTree | undefined) => {
		return category?.id === ROOT_NAVIGATION_ID
	}

	const getCategoriesToDisplay = useCallback(
		({ activeCategory, deviceType }: GetCategoriesToDisplayArgs) => {
			if (!isRootCategory(activeCategory)) {
				// If the we are not at the root then the categories to display are the children
				//	of the active category and then any categories where the parent should be
				// "see-through" - meaning that the children should be made visible at the same level.
				return Navigation.getChildCategories(navList, {
					parentId: activeCategory?.id || ROOT_NAVIGATION_ID,
					deviceType,
					sortBy: 'position',
					allowSeeThrough: true,
				})
			}

			// If we are at the root then we need to display the top level categories but not show
			//	any of the "see through categories".
			return Navigation.getChildCategories(navList, {
				parentId: activeCategory?.id || ROOT_NAVIGATION_ID,
				deviceType,
				sortBy: 'position',
				allowSeeThrough: false,
			})
		},
		[navList],
	)

	const value = {
		showMobileMenu,
		setShowMobileMenu,
		navList,
		getCategoriesToDisplay,
	}

	return <navbarProviderContext.Provider value={value}>{children}</navbarProviderContext.Provider>
}
