import clsx from 'clsx'
import React, { useCallback, useEffect, useState } from 'react'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import SearchIcon from '~/components/primitives/icons/SearchIcon'
import CloseIcon from '~/components/primitives/icons/CloseIcon'
import styles from './SearchInputForm.module.scss'
import useNavigation from '~/components/hooks/useNavigation'

const SearchInputForm = ({
	searchQuery,
	onSearchTextChanged,
	handleSearchRedirect,
	onClearSearchTerm,
	onSetSubNavOpen,
}) => {
	const formatMessage = useFormatMessage()
	const [placeholderText, setPlaceholderText] = useState(formatMessage('search-placeholder'))

	const handlePlaceholderTextChange = () => {
		onSetSubNavOpen('')
		setPlaceholderText(() => (placeholderText ? '' : formatMessage('search-placeholder')))
	}

	const handleEnterKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key !== 'Enter') return
		const target = event.currentTarget
		target.value = ''
		target.blur()
		handleSearchRedirect()
	}

	const handleClearSearch = useCallback(() => {
		const el = document.getElementById('search-input') as HTMLInputElement
		if (el) {
			el.value = ''
		}
		onClearSearchTerm()
	}, [onClearSearchTerm])

	const activeSearchIcon = !placeholderText || searchQuery
	useNavigation({ onRouteChange: handleClearSearch })

	useEffect(() => {
		// when a user performs certain actions the parent component will update the searchQuery to '' but, html input field
		// will still contain a value so just clear this out in that situation
		if (searchQuery === '') {
			handleClearSearch()
		}
	}, [handleClearSearch, searchQuery])

	return (
		<form onSubmit={(e) => e.preventDefault()}>
			<div className={styles['dt-search-field']}>
				<input
					id="search-input"
					type="text"
					placeholder={placeholderText}
					onFocus={handlePlaceholderTextChange}
					onBlur={handlePlaceholderTextChange}
					onChange={onSearchTextChanged}
					onKeyDown={handleEnterKeyDown}
					defaultValue={searchQuery || ''}
					autoComplete={'off'}
					data-testid="mobile-search-bar"
					aria-label={formatMessage('search-keyword-or-item-no')}
					aria-controls="search-results"
					aria-haspopup="listbox"
					maxLength={50}
				/>
				<span
					aria-label={formatMessage('clear')}
					name="clear-button"
					role="button"
					onClick={handleClearSearch}
					className={clsx(styles['dt-search-field-clear'], { [styles.visible]: searchQuery !== '' })}
				>
					<CloseIcon size="XS" />
				</span>
				<span
					aria-label={formatMessage('search')}
					name="search-button"
					role="button"
					onClick={handleSearchRedirect}
					className={`${styles['dt-search-field-search']} ${activeSearchIcon ? styles.active : ''}`}
				>
					<SearchIcon size="SM" />
				</span>
			</div>
		</form>
	)
}

export default SearchInputForm
