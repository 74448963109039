import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import FavoriteOutlinedIcon from '~/components/primitives/icons/FavoriteOutlinedIcon'
import InfoOutlinedIcon from '~/components/primitives/icons/InfoOutlinedIcon'
import MailOutline from '~/components/primitives/icons/MailIcon'
import PersonOutlinedIcon from '~/components/primitives/icons/PersonOutlinedIcon'
import { getLocaleUrl } from '~/lib/i18n/urls'
import { MobileNav, NavItem } from '~/components/shared/MobileNav/MobileNav'
import { useMemo } from 'react'

interface Props {
	locale: string
	loginCallback: () => void
	registerCallback: () => void
	handleCloseMenu: () => void
}

export default function MobileUnregisteredBottomNav({
	locale,
	loginCallback,
	handleCloseMenu,
	registerCallback,
}: Readonly<Props>) {
	const savedItemsUrl = useMemo(() => getLocaleUrl('/saved-items', locale), [locale])
	const formatMessage = useFormatMessage()

	return (
		<MobileNav>
			<NavItem onClick={loginCallback} label={formatMessage('login')}>
				<PersonOutlinedIcon />
			</NavItem>
			<NavItem onClick={registerCallback} label={formatMessage('register')}>
				<MailOutline size="SM" />
			</NavItem>
			<NavItem href={savedItemsUrl} onClick={handleCloseMenu} label={formatMessage('saved')}>
				<FavoriteOutlinedIcon />
			</NavItem>
			<NavItem href="https://help.underarmour.com/s/" target="_blank" label={formatMessage('help')}>
				<InfoOutlinedIcon size="SM" />
			</NavItem>
		</MobileNav>
	)
}
