import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import type { UserWithSession } from '~/lib/client-only/auth/types'
import FavoriteOutlinedIcon from '~/components/primitives/icons/FavoriteOutlinedIcon'
import InfoOutlinedIcon from '~/components/primitives/icons/InfoOutlinedIcon'
import OrderHistoryIcon from '~/components/primitives/icons/OrderHistoryIcon'
import PersonOutlinedIcon from '~/components/primitives/icons/PersonOutlinedIcon'
import { getLocaleUrl } from '~/lib/i18n/urls'
import { MobileNav, NavItem } from '~/components/shared/MobileNav/MobileNav'
import { useMemo } from 'react'
import { getPublicConfig } from '~/lib/client-server/config'

interface Props {
	customer: UserWithSession
	toggleMenuOpen: () => void
	handleCloseMenu: () => void
	locale: string
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function MobileRegisteredBottomNav({
	customer,
	locale,
	handleCloseMenu,
	toggleMenuOpen,
}: Readonly<Props>) {
	const formatMessage = useFormatMessage()
	const savedUrl = useMemo(() => getLocaleUrl('/saved-items', locale), [locale])
	const orderHistoryUrl = useMemo(() => getLocaleUrl('/my-order-history', locale), [locale])
	const isLoyaltyNavEnabled = getPublicConfig().loyaltynav.enabled
	const accountLabel = useMemo(() => {
		if (isLoyaltyNavEnabled) return formatMessage('account')
		return customer?.firstName || formatMessage('my-account')
	}, [formatMessage, isLoyaltyNavEnabled, customer?.firstName])

	return (
		<MobileNav>
			<NavItem onClick={toggleMenuOpen} label={accountLabel}>
				<PersonOutlinedIcon />
			</NavItem>
			<NavItem onClick={handleCloseMenu} href={savedUrl} label={formatMessage('saved')}>
				<FavoriteOutlinedIcon />
			</NavItem>
			<NavItem onClick={handleCloseMenu} href={orderHistoryUrl} label={formatMessage('order-history')}>
				<OrderHistoryIcon />
			</NavItem>
			<NavItem href="https://help.underarmour.com/s/" target="_blank" label={formatMessage('help')}>
				<InfoOutlinedIcon size="SM" />
			</NavItem>
		</MobileNav>
	)
}
