import { memo } from 'react'
import type { IconProps } from '~/lib/icons'
import { getEnhancedIconProps } from '~/lib/icons'

const ArrowCloseDropDownIcon = (props: IconProps) => (
	<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...getEnhancedIconProps(props)}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M0 7.5L6 0.5L12 7.5H0Z"
			fill="currentColor"
			transform="translate(2, 4)"
		/>
	</svg>
)

export default memo(ArrowCloseDropDownIcon)
