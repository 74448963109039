import parse, { Element, type HTMLReactParserOptions } from 'html-react-parser'
import type { ImageProps } from 'next/image'

import React from 'react'
import clsx from 'clsx'
import Image from '~/components/primitives/Image'
import styles from './DesktopSubNav.module.scss'

import { DesktopCategory } from './DesktopCategory'
import type { NavigationTree } from '~/lib/navigation'

interface Props {
	nav: NavigationTree
}

const parserOptions: HTMLReactParserOptions = {
	replace: (domNode) => {
		if (domNode instanceof Element && domNode.attribs && domNode.name === 'img') {
			const { alt = '', class: className = '', ...props } = domNode.attribs as ImageProps & { [name: string]: string }
			return <Image alt={alt} className={className} {...props} width="400" height="400" />
		}
		return null
	},
}

function DesktopSubNav({ nav }: Props) {
	const isCurryNav = nav.id === 'Curry-Brand'

	// Use clsx to conditionally apply classes
	const wrapperClassName = clsx({
		[styles['sub-menu-category-list']]: !isCurryNav,
		[styles['sub-menu-category-list-curry']]: isCurryNav,
	})

	return (
		<ul className={wrapperClassName}>
			{nav?.banner?.body && parse(nav.banner.body, parserOptions)}
			{nav?.categories && nav?.categories.map((c) => c.showDesktop && <DesktopCategory key={c.id} maincat={c} />)}
		</ul>
	)
}

export default React.memo(DesktopSubNav)
