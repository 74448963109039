import { memo } from 'react'

import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import { useLocale } from '~/components/hooks/useLocale'
import useLanguageSelection from '~/components/hooks/useLanguageSelection'
import DropMenu, { DropMenuOption } from '~/components/primitives/DropMenu/DropMenu'

import styles from './LanguageSelector.module.scss'

export interface Props {
	currentPath: string
}

const LANGUAGE_PREFIX = 'language-'

export default memo(function LanguageSelector(props: Props) {
	const locale = useLocale()
	const formatMessage = useFormatMessage()
	const { alternateLanguages, currentLanguage, getRouteByLanguage } = useLanguageSelection(locale, props.currentPath)

	if (alternateLanguages.length === 0) {
		return (
			<span className={styles['header-utility-heading']}>{formatMessage(`${LANGUAGE_PREFIX}${currentLanguage}`)}</span>
		)
	}

	const buttonChildren = (
		<>
			<span data-testid={'dropdown__selected-language'}>{formatMessage(`${LANGUAGE_PREFIX}${currentLanguage}`)}</span>{' '}
		</>
	)

	const dropdownChildren = (
		<>
			<DropMenuOption className={styles['dropdown__options-header']}>
				<p>{formatMessage('language')}</p>
			</DropMenuOption>
			<DropMenuOption className={styles['dropdown__selected-language']}>
				<p>{formatMessage(`${LANGUAGE_PREFIX}${currentLanguage}`)}</p>
			</DropMenuOption>

			{alternateLanguages.map((language) => (
				<DropMenuOption key={language}>
					<a className={styles['dropdown__alternate-language']} href={getRouteByLanguage(language)}>
						{formatMessage(`${LANGUAGE_PREFIX}${language}`)}
					</a>
				</DropMenuOption>
			))}
		</>
	)

	return (
		<DropMenu buttonLabel={buttonChildren} name="language">
			{dropdownChildren}
		</DropMenu>
	)
})
