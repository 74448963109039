import React, { useCallback, useState } from 'react'
import SearchIcon from '~/components/primitives/icons/SearchIcon'

import { useDeactivateBodyScroll } from '~/components/hooks/useDeactivateBodyScroll'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import { LocaleLink } from '~/components/primitives/LocaleLink/LocaleLink'
import SearchInputForm from '~/components/shared/SearchInputForm/SearchInputForm'
import UALogo from '~/components/shared/UALogo'
import CloseIcon from '~/components/primitives/icons/CloseIcon'
import styles from './MobileSearch.module.scss'
import useNavigation from '~/components/hooks/useNavigation'

interface Props {
	onClearSearch: () => void
	onSearchRedirect: () => void
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
	onEnterKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void
	searchTerm: string
	children: React.ReactNode
	onSetSubNavOpen: (string) => void
}

const MobileSearch = ({ onSetSubNavOpen, onSearchRedirect, onChange, searchTerm, onClearSearch, children }: Props) => {
	const formatMessage = useFormatMessage()
	const [isMobileSearchActive, setIsMobileSearchActive] = useState(false)
	const setIsMobileSearchActiveCallback = useCallback(() => setIsMobileSearchActive(false), [])

	useNavigation({ onRouteChange: setIsMobileSearchActiveCallback })

	const handleToggleSearchPanel = () => {
		setIsMobileSearchActive((prev) => !prev)
	}

	const handleSearch = () => {
		setIsMobileSearchActive(false)
		onSearchRedirect()
	}

	// hook disabling background document body scroll when mobile search slideout is active
	useDeactivateBodyScroll(isMobileSearchActive)

	return (
		<>
			<button
				type="button"
				className={styles['mobile-search-icon']}
				aria-label={formatMessage('search-products')}
				onClick={handleToggleSearchPanel}
			>
				<SearchIcon />
			</button>

			<div className={`${styles['mobile-slideout-search']} ${isMobileSearchActive ? styles.active : ''}`}>
				<div className={styles['ms-content-wrapper']}>
					<div className={styles['ms-header']}>
						<div className={styles['ms-logo']}>
							<LocaleLink href="/" aria-label={formatMessage('return-to-homepage')}>
								<UALogo width={42} height={24} color={'#000'} />
							</LocaleLink>
						</div>
						<span
							className={styles['ms-close-icon']}
							onClick={handleToggleSearchPanel}
							aria-label={formatMessage('close')}
						>
							<CloseIcon size="SM" />
						</span>
					</div>
					<div className={styles['ms-search-bar']}>
						<SearchInputForm
							onSetSubNavOpen={onSetSubNavOpen}
							searchQuery={searchTerm}
							onSearchTextChanged={onChange}
							handleSearchRedirect={handleSearch}
							onClearSearchTerm={onClearSearch}
						/>
					</div>
					{children}
				</div>
			</div>
		</>
	)
}

export default MobileSearch
