import { useMemo } from 'react'
import { getLoyaltyPointsNextLevel, getLoyaltyPointsAvailableRewards } from '~/lib/profile'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import { ArcScale } from '~/components/primitives/ArcScale/ArcScale'
import { IconButton } from '~/components/primitives/Button/IconButton'
import { UARewardsNoBrackets } from '~/components/primitives/icons'
import ArrowIcon from '~/components/primitives/icons/ArrowIcon'
import { LocaleLink } from '~/components/primitives/LocaleLink/LocaleLink'
import styles from './RewardsSummary.module.scss'
import { useLayoutMeasurement } from '~/components/hooks/useLayoutMeasurement'
import { LOYALTY_VISITED_STORAGE_KEY } from '~/lib/constants'
import AnimatedCounter from '~/components/primitives/AnimatedCounter/AnimatedCounter'
import Embellishments from '~/components/shared/Embellishments/Embellishments'
import { sessionStorageGet } from '~/lib/client-only/storage'

type RewardsSummaryProps = {
	customerPoints: number
	isCurrentPath: boolean
	onClick?: () => void
	isOpen?: boolean
	size?: 'SM' | 'LG' | undefined
}

export const RewardsSummary = ({
	customerPoints,
	isCurrentPath,
	onClick,
	isOpen,
	size = 'LG',
}: RewardsSummaryProps) => {
	const formatMessage = useFormatMessage()
	const { isMobile } = useLayoutMeasurement()
	const pointsAway = useMemo(() => {
		const nextLevelPoints = getLoyaltyPointsNextLevel(customerPoints)
		return customerPoints < nextLevelPoints ? nextLevelPoints - customerPoints : 0
	}, [customerPoints])

	const pointsPercent = useMemo(() => {
		return customerPoints < 2500 ? (customerPoints / 2500) * 100 : 100
	}, [customerPoints])

	const availableRewards = useMemo(() => getLoyaltyPointsAvailableRewards(customerPoints), [customerPoints])

	const pointsAwayMessage = useMemo(
		() =>
			formatMessage('loyalty-summary', {
				points: <strong key={`point`}>{formatMessage('loyalty-summary-points', { points: pointsAway })}</strong>,
			}),
		[formatMessage, pointsAway],
	)

	// we show animation on mobile - every time, for desktop only once per session
	const disableAnimation = !isMobile && !!sessionStorageGet(LOYALTY_VISITED_STORAGE_KEY)
	// accessibility label for the icon button
	const iconButtonLabel = formatMessage('select-category', {
		category: formatMessage('rewards-locker').toString(),
	})

	return (
		<div className={`${styles['rewards-summary']}${size === 'SM' ? ` ${styles['rewards-summary-small']}` : ``}`}>
			<LocaleLink
				href={'/my-rewards-locker'}
				className={styles.scale}
				aria-current={isCurrentPath ? 'page' : undefined}
				onClick={onClick}
			>
				<Embellishments
					className={styles.embelishments}
					progress={pointsPercent}
					animationDisabled={disableAnimation}
					startAnimation={isOpen}
				/>
				<div className={styles['point-counter-container']}>
					<ArcScale fillPercentage={pointsPercent} startAnimation={isOpen} animationDisabled={disableAnimation} />
					<span className={styles['point-counts']}>
						<UARewardsNoBrackets className={styles['rewards-logo']} />
						<AnimatedCounter value={customerPoints} animationDisabled={disableAnimation} startAnimation={isOpen} />
						<span className={styles['points-label']}>{formatMessage('loyalty-points')}</span>
					</span>
				</div>
				<span className={styles['points-away']}>{pointsAwayMessage}</span>
			</LocaleLink>
			<div className={styles['nav-link']}>
				<LocaleLink href={'/my-rewards-locker'} aria-current={isCurrentPath ? 'page' : undefined} onClick={onClick}>
					{formatMessage('rewards-locker')}
					<span>{availableRewards}</span>
					{` `}
					<IconButton className={styles['mobile-forward-arrow']} label={iconButtonLabel}>
						<ArrowIcon size="MD" />
					</IconButton>
				</LocaleLink>
			</div>
		</div>
	)
}
