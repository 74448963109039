import { useCallback, useState } from 'react'
import clsx from 'clsx'
import { sessionStorageGet, sessionStorageSet } from '~/lib/client-only/storage'
import { getPublicConfig } from '~/lib/client-server/config'
import { LOYALTY_VISITED_STORAGE_KEY } from '~/lib/constants'
import { isNonNullish } from '~/types/strict-null-helpers'
import { useLoyalty } from '~/components/providers/LoyaltyProvider/LoyaltyProvider'
import { useSession } from '~/components/providers/UaSessionProvider/UaSessionProvider'
import AccountNavLinks from '~/components/layouts/NavbarLayout/Navigation/AccountNavLinks'
import DropMenu, { DropMenuOption } from '~/components/primitives/DropMenu/DropMenu'
import { RewardsSummary } from '~/components/layouts/NavbarLayout/Navigation/RewardsSummary/RewardsSummary'
import styles from './UserDropMenu.module.scss'

export const UserDropMenu = ({ currentPath, buttonLabel }) => {
	const { user: customer } = useSession()
	const isLoyaltyNavEnabled = getPublicConfig().loyaltynav.enabled
	const isLoyaltyUser = customer?.isRegistered && customer.isLoyalty
	const { loyalty } = useLoyalty()
	const showLoyalty = isLoyaltyNavEnabled && isLoyaltyUser && isNonNullish(loyalty?.customerPoints)
	const [isOpen, setIsOpen] = useState(false)

	const onDropMenuOpen = useCallback(() => {
		setIsOpen(true)
	}, [])

	const onDropMenuClose = useCallback(() => {
		setIsOpen(false)
		if (!sessionStorageGet(LOYALTY_VISITED_STORAGE_KEY) && showLoyalty) {
			sessionStorageSet(LOYALTY_VISITED_STORAGE_KEY, 'true')
		}
	}, [showLoyalty])

	return (
		<div className={clsx(showLoyalty && styles.container)}>
			<DropMenu buttonLabel={buttonLabel} name="my-account" onOpen={onDropMenuOpen} onClose={onDropMenuClose}>
				{(handleClose) =>
					showLoyalty ? (
						<>
							<DropMenuOption className={styles.rewards}>
								<RewardsSummary
									customerPoints={loyalty?.customerPoints}
									isCurrentPath={currentPath.includes('my-rewards-locker')}
									onClick={handleClose}
									size="SM"
									isOpen={isOpen}
								/>
							</DropMenuOption>
							<DropMenuOption className={styles.nav}>
								<ul>
									<AccountNavLinks
										currentPath={currentPath}
										isChildOfDropMenu
										onLinkClick={handleClose}
										hideRewardsLink
									/>
								</ul>
							</DropMenuOption>
						</>
					) : (
						<AccountNavLinks currentPath={currentPath} isChildOfDropMenu onLinkClick={handleClose} />
					)
				}
			</DropMenu>
		</div>
	)
}
