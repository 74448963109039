'use client'

/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { getPublicConfig } from '~/lib/client-server/config'
import { useAnalytics } from '~/components/hooks/useAnalytics'
import { useAuthModals } from '~/components/hooks/useAuthModals'
import { useDeactivateBodyScroll } from '~/components/hooks/useDeactivateBodyScroll'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import { useLoyalty } from '~/components/providers/LoyaltyProvider/LoyaltyProvider'
import { useSession } from '~/components/providers/UaSessionProvider/UaSessionProvider'
import { useLayoutMeasurement } from '~/components/hooks/useLayoutMeasurement'
import { useLocale } from '~/components/hooks/useLocale'
import { MyAccountLinks } from '~/components/layouts/MyAccount/MyAccountLinks'
import { EnrollmentSource } from '~/lib/types/loyalty.interface'
import Navigation from '~/lib/client-only/navigation'
import UALogo from '~/components/shared/UALogo'
import MobileChangeLocationButton from '~/components/layouts/NavbarLayout/Navigation/MobileNav/MobileChangeLocationButton'
import styles from '~/components/layouts/NavbarLayout/Navigation/MobileNav/MobileNav.module.scss'
import MobileNavItem from '~/components/layouts/NavbarLayout/Navigation/MobileNav/MobileNavItem'
import MobileRegisteredBottomNav from '~/components/layouts/NavbarLayout/Navigation/MobileNav/MobileRegisteredBottomNav'
import MobileUnregisteredBottomNav from '~/components/layouts/NavbarLayout/Navigation/MobileNav/MobileUnregisteredBottomNav'
import { ensureNumber, isNonNullish } from '~/types/strict-null-helpers'
import { LOYALTY_VISITED_STORAGE_KEY, ROOT_NAVIGATION_ID } from '~/lib/constants'
import { RewardsSummary } from '~/components/layouts/NavbarLayout/Navigation/RewardsSummary/RewardsSummary'
import { sessionStorageGet, sessionStorageSet } from '~/lib/client-only/storage'
import { usePathname } from 'next/navigation'
import { useNavbar } from '../../NavbarProvider'
import type { NavigationTree } from '~/lib/navigation'
import { IconButton } from '~/components/primitives/Button/IconButton'
import ArrowIcon from '~/components/primitives/icons/ArrowIcon'
import { LocaleLink } from '~/components/primitives/LocaleLink/LocaleLink'
import { CloseIcon } from '~/components/primitives/icons'
import { RegisteredGreeting } from './RegisteredGreeting'

export type MobileNavProps = {
	navList: NavigationTree[]
}

export default function MobileNav() {
	const currentPath = usePathname()
	const locale = useLocale()
	const { showMobileMenu, setShowMobileMenu } = useNavbar()
	const { navList, getCategoriesToDisplay } = useNavbar()

	// ------------ hooks ----------------
	const formatMessage = useFormatMessage()
	const { user: customer } = useSession()
	const { analyticsManager } = useAnalytics()
	const { showLoginModal, showRegisterModal } = useAuthModals()
	const { setObserveTopMargin } = useLayoutMeasurement()

	// hook disabling background document body scroll when mobile menu slideout is active
	useDeactivateBodyScroll(showMobileMenu)

	// ------------ state ----------------
	const [activeCategory, setActiveCategory] = useState(Navigation.getCategoryById(navList, ROOT_NAVIGATION_ID))

	// My Account selection element
	const [isAccountMobileMenuOpen, setAccountMobileMenuOpen] = useState(false)

	/**
	 * Following a logout, this will close the Account Menu panel when a close menu event is passed through.
	 * @param isOpen
	 */
	useEffect(() => {
		if (customer?.isRegistered) {
			setAccountMobileMenuOpen(false)
			setShowMobileMenu(false)
		}
	}, [customer])

	useEffect(() => {
		// If the active category is not in the navList anymore, set the active category to the root category.  And
		//	even if it is in the navList, we need to update the active category to the new category object in the navList.
		let newActiveCategory = activeCategory?.id ? Navigation.getCategoryById(navList, activeCategory?.id) : undefined
		if (!newActiveCategory) {
			newActiveCategory = Navigation.getCategoryById(navList, ROOT_NAVIGATION_ID)
		}
		setActiveCategory(newActiveCategory)
	}, [navList])

	// ---------------- callbacks --------------
	const loginCallback = useCallback(() => showLoginModal(undefined, EnrollmentSource.ECOMMLOGIN), [showLoginModal])
	const toggleAccountMenuOpen = useCallback(
		() => setAccountMobileMenuOpen(!isAccountMobileMenuOpen),
		[isAccountMobileMenuOpen, setAccountMobileMenuOpen],
	)
	const registerCallback = useCallback(
		() => showRegisterModal(undefined, EnrollmentSource.ECOMMREGISTRATION),
		[showLoginModal],
	)

	const isRootCategory = useCallback((category: NavigationTree | undefined) => {
		return category?.id === ROOT_NAVIGATION_ID
	}, [])

	const handleCloseMenu = useCallback(() => {
		setActiveCategory(Navigation.getCategoryById(navList, ROOT_NAVIGATION_ID))
		setShowMobileMenu(false)
		setAccountMobileMenuOpen(false)
	}, [])

	const setActiveCategoryById = useCallback(
		(id: string) => {
			setActiveCategory(Navigation.getCategoryById(navList, id))
		},
		[navList],
	)

	// effect is used when branch-banner is opened/closed
	useEffect(() => {
		setObserveTopMargin(true)
	}, [setObserveTopMargin])

	// effect is used when user visit loyalty first time
	useEffect(() => {
		if (!sessionStorageGet(LOYALTY_VISITED_STORAGE_KEY) && isAccountMobileMenuOpen) {
			sessionStorageSet(LOYALTY_VISITED_STORAGE_KEY, 'true')
		}
	}, [isAccountMobileMenuOpen])

	const categoriesToDisplay = useMemo(
		() => getCategoriesToDisplay({ activeCategory, deviceType: 'mobile' }),
		[getCategoriesToDisplay, activeCategory],
	)

	// Observe resizing of mobile bottom nav
	const bottomNavRef = useRef<HTMLLIElement>(null)
	useEffect(() => {
		// Check if element exists. bottomNavRef is a conditionally rendered element.
		// This effect should re-run when isRegistered, isGuest and activeCategoryId values change.
		const element = bottomNavRef?.current
		if (element && (customer?.isRegistered || customer?.isGuest) && isRootCategory(activeCategory)) {
			const observer = new ResizeObserver((entries) => {
				entries.forEach((entry) => {
					document.documentElement.style.setProperty(
						'--mobile-bottom-nav-height',
						`${Math.floor(ensureNumber(entry?.contentRect?.height))}px`,
					)
				})
			})
			observer.observe(element as Element)

			return () => {
				observer?.disconnect()
			}
		}
		return () => null
	}, [customer?.isRegistered, customer?.isGuest, activeCategory])

	// ---------------- loyalty --------------
	const isLoyaltyNavEnabled = getPublicConfig().loyaltynav.enabled
	const isLoyaltyUser = customer?.isRegistered && customer.isLoyalty
	const { loyalty: loyaltyRewardsData } = useLoyalty()
	const showLoyalty = isLoyaltyNavEnabled && isLoyaltyUser && isNonNullish(loyaltyRewardsData?.customerPoints)

	return (
		<>
			<nav
				className={clsx(styles['mobile-nav-menu'], isLoyaltyNavEnabled && styles['mobile-nav-menu--loyalty'])}
				data-open={showMobileMenu}
			>
				<div className={styles['mobile-menu-header']}>
					{activeCategory && !isRootCategory(activeCategory) && (
						<IconButton
							className={styles['mobile-back-button']}
							label={
								Navigation.isTopLevelCategory(activeCategory)
									? formatMessage('go-back-to', { to: formatMessage('main-navigation') })
									: formatMessage('go-back-to', {
											to: Navigation.getParentCategory(navList, activeCategory.id)?.name,
									  })
							}
							onClick={() => setActiveCategory(Navigation.getParentCategory(navList, activeCategory.id))}
						>
							<ArrowIcon size="SM" className={styles['arrow-back']} />
						</IconButton>
					)}
					<LocaleLink href="/" className={styles['mobile-menu-logo']} onClick={handleCloseMenu}>
						<UALogo width={41} height={24} color={'#000'} />
					</LocaleLink>
					{/* When they Close it, It will open from First level */}
					<IconButton
						className={styles['mobile-menu-close']}
						label={formatMessage('close-navigation')}
						onClick={handleCloseMenu}
					>
						<CloseIcon size="SM" />
					</IconButton>
				</div>
				{customer?.isRegistered && isLoyaltyNavEnabled && (
					<button
						type="button"
						className={styles['mobile-menu-greeting']}
						onClick={() => setAccountMobileMenuOpen(!isAccountMobileMenuOpen)}
					>
						<RegisteredGreeting customer={customer} points={loyaltyRewardsData?.customerPoints} useTimedGreeting />
					</button>
				)}
				{activeCategory && !isRootCategory(activeCategory) && (
					<div className={styles['mobile-menu-subheader']}>
						<LocaleLink
							href={activeCategory?.url}
							name={activeCategory?.name}
							onClick={() => {
								analyticsManager.trackNavigation(activeCategory, locale)
								handleCloseMenu()
							}}
						>
							{activeCategory.name}
						</LocaleLink>
					</div>
				)}
				<ul className={clsx(styles['mobile-menu'], isRootCategory(activeCategory) && styles['mobile-menu-root-lvl'])}>
					{activeCategory?.shopAllVisible && (
						<li className={styles['mobile-menu-item']}>
							<LocaleLink
								href={activeCategory.url}
								name={activeCategory.name}
								onClick={() => {
									analyticsManager.trackNavigation(activeCategory, locale)
									handleCloseMenu()
								}}
							>
								<span>
									{formatMessage('shop-all', {
										category: activeCategory.name,
									})}
								</span>
							</LocaleLink>
						</li>
					)}
					{activeCategory && isRootCategory(activeCategory) ? (
						<>
							{categoriesToDisplay
								.filter((c) => !c.expand)
								.map((nav) => (
									<MobileNavItem
										key={nav.id}
										navCategory={nav}
										setMobileMenu={setShowMobileMenu}
										setActiveCategoryId={setActiveCategoryById}
										isRoot={isRootCategory(activeCategory)}
									/>
								))}
							{categoriesToDisplay
								.filter((c) => c.expand)
								.map((expandedCat: NavigationTree) => (
									<Fragment key={expandedCat.id}>
										<li
											key={expandedCat.id}
											className={clsx(
												styles['mobile-expanded-header'],
												isRootCategory(activeCategory) && styles['is-root'],
											)}
										>
											<a>
												<span>{expandedCat.name}</span>
											</a>
										</li>
										{expandedCat.categories?.map((cat: NavigationTree) => (
											<MobileNavItem
												key={cat.id}
												navCategory={cat}
												setMobileMenu={setShowMobileMenu}
												setActiveCategoryId={setActiveCategoryById}
												isRoot={isRootCategory(activeCategory)}
												isExpanded={true}
											/>
										))}
									</Fragment>
								))}
						</>
					) : (
						categoriesToDisplay.map((nav) =>
							!nav.expand ? (
								<MobileNavItem
									key={nav.id}
									navCategory={nav}
									setMobileMenu={setShowMobileMenu}
									setActiveCategoryId={setActiveCategoryById}
									isRoot={isRootCategory(activeCategory)}
								/>
							) : (
								<Fragment key={nav.id}>
									<li
										key={nav.id}
										className={clsx(
											styles['mobile-expanded-header'],
											isRootCategory(activeCategory) && styles['is-root'],
										)}
									>
										<a>
											<span>{nav.name}</span>
										</a>
									</li>
									{nav.categories?.map((cat: NavigationTree) => (
										<MobileNavItem
											key={cat.id}
											navCategory={cat}
											setMobileMenu={setShowMobileMenu}
											setActiveCategoryId={setActiveCategoryById}
											isRoot={isRootCategory(activeCategory)}
											isExpanded={true}
										/>
									))}
								</Fragment>
							),
						)
					)}
					{locale && isRootCategory(activeCategory) && (
						<MobileChangeLocationButton path={currentPath} locale={locale} onClick={handleCloseMenu} />
					)}

					{customer?.isRegistered && isRootCategory(activeCategory) && (
						<li ref={bottomNavRef} className={styles['account-bottom-nav']} data-testid="bottom-nav">
							<MobileRegisteredBottomNav
								customer={customer}
								locale={locale}
								handleCloseMenu={handleCloseMenu}
								toggleMenuOpen={toggleAccountMenuOpen}
							/>
						</li>
					)}
					{customer?.isGuest && isRootCategory(activeCategory) && (
						<li ref={bottomNavRef} className={styles['account-bottom-nav']} data-testid="bottom-nav">
							<MobileUnregisteredBottomNav
								locale={locale}
								registerCallback={registerCallback}
								handleCloseMenu={handleCloseMenu}
								loginCallback={loginCallback}
							/>
						</li>
					)}
				</ul>
				<ul
					className={`
						${styles['account-submenu-mobile-wrapper']}
						${styles['mobile-no-transition']} 
						${isAccountMobileMenuOpen ? styles['mobile-account-menu'] : null}
					`}
				>
					<li className={`${styles['mobile-menu-header']} `}>
						<IconButton
							className={styles['mobile-back-button']}
							onClick={() => setAccountMobileMenuOpen(!isAccountMobileMenuOpen)}
							label={formatMessage('back')}
						>
							<ArrowIcon size="SM" className={styles['arrow-back']} />
						</IconButton>
						<h3 className={styles['mobile-navigation-title']}>
							<RegisteredGreeting customer={customer} useTimedGreeting={isLoyaltyNavEnabled} />
						</h3>
						<IconButton
							className={styles['mobile-menu-close']}
							onClick={handleCloseMenu}
							label={formatMessage('close')}
						>
							<CloseIcon size="SM" />
						</IconButton>
					</li>
					{showLoyalty && (
						<RewardsSummary
							customerPoints={loyaltyRewardsData?.customerPoints}
							isCurrentPath={currentPath.includes('my-rewards-locker')}
							onClick={handleCloseMenu}
							isOpen={isAccountMobileMenuOpen}
						/>
					)}
					<MyAccountLinks
						currentPath={currentPath}
						showLogout={true}
						showNavArrows={true}
						onClick={handleCloseMenu}
						showLoyalty={!isLoyaltyNavEnabled}
					/>
				</ul>
			</nav>
			<span onClick={() => setShowMobileMenu(false)} data-open={showMobileMenu} className={styles['close-menu']} />
		</>
	)
}
