import { useCallback, useEffect, useMemo, useState } from 'react'
import { getAlternateLanguages, getCountryCodeByLocale, getLanguageByLocale } from '~/lib/i18n/locale'
import { getLocaleUrl, getUrlWithoutLocale } from '~/lib/i18n/urls'
import { addTrailingSlash } from '~/lib/utils'

export default function useLanguageSelection(locale: string, path: string) {
	/**
	 * The following is what the Vercel team provided:
	 *
	 * > Hi, so after further investigation it looks like this is a quirk with usePathname()
	 * > during our static generation handling. We're looking into long term fix although if
	 * > you delay reading the pathname value until on the client via a useEffect() this
	 * > should avoid this quirk for now
	 *
	 * Unfortunately, a useMemo() does not work as expected, either
	 */
	// Ensure route has a trailing slash (root path locales are correctly parsed)
	// const routeWithoutLocale = getUrlWithoutLocale(addTrailingSlash(path))

	const [routeWithoutLocale, setRouteWithoutLocale] = useState<string>()

	useEffect(() => {
		setRouteWithoutLocale(getUrlWithoutLocale(addTrailingSlash(path)))
	}, [path])

	const [alternateLanguages, currentCountry, currentLanguage, selectableLanguages] = useMemo(() => {
		const alternateLanguages = getAlternateLanguages(locale)
		const currentLanguage = getLanguageByLocale(locale)
		const selectableLanguages = alternateLanguages.length ? [currentLanguage, ...alternateLanguages] : []
		return [alternateLanguages, getCountryCodeByLocale(locale), currentLanguage, selectableLanguages]
	}, [locale])

	const isCategoryPage = useMemo(() => routeWithoutLocale?.startsWith('/c/'), [routeWithoutLocale])
	const isTemplatePage = useMemo(() => routeWithoutLocale?.startsWith('/t/'), [routeWithoutLocale])

	const getRouteByLanguage = useCallback(
		(language: string) => {
			const locale = `${language}-${currentCountry}`
			// Any URL which requires translation should be redirected to homepage
			if (!routeWithoutLocale || isCategoryPage || isTemplatePage) {
				return `/${locale}/`
			}
			return getLocaleUrl(routeWithoutLocale, locale)
		},
		[currentCountry, routeWithoutLocale, isCategoryPage, isTemplatePage],
	)

	return {
		alternateLanguages,
		currentCountry,
		currentLanguage,
		getRouteByLanguage,
		selectableLanguages,
	}
}
