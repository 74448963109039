import { type CSSProperties, memo, useEffect, useState } from 'react'
import styles from './Embellishments.module.scss'
import clsx from 'clsx'

interface DecorationWrapperProps {
	/** how much embellishment should rotate and change in size, 0-100 */
	progress: number

	/** wrapper className */
	className?: string

	/** transition fn, for ex: ease-in-out, cubic-bezier(0.1, 0.7, 1, 0.1) */
	animationFn?: string

	/** in ms */
	animationTime?: number

	/** show embellishments without animation */
	animationDisabled?: boolean

	/** is ready to show progress animation */
	startAnimation?: boolean
}

const PlusThick = ({ className, svgStyle }: { className?: string; svgStyle?: CSSProperties }) => {
	return (
		<div className={clsx(styles.plus, className)}>
			<svg
				viewBox="0 0 15 15"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				preserveAspectRatio="xMinYMax meet"
				style={svgStyle}
			>
				<path d="M3.40039 8.25391L11.1598 6.30664" stroke="currentColor" stroke-width="1.5" />
				<path d="M6.30664 3.40039L8.2539 11.1598" stroke="currentColor" stroke-width="1.5" />
			</svg>
		</div>
	)
}

const PlusThin = ({ className, svgStyle }: { className?: string; svgStyle?: CSSProperties }) => {
	return (
		<div className={clsx(styles.plus, className)}>
			<svg
				viewBox="0 0 34 34"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				preserveAspectRatio="xMinYMax meet"
				style={svgStyle}
			>
				<path d="M9.61719 10.1836L23.9767 24.105" stroke="currentColor" stroke-width="1.5" />
				<path d="M23.9277 9.78906L10.0063 24.1485" stroke="currentColor" stroke-width="1.5" />
			</svg>
		</div>
	)
}

const Circle = ({ className, svgStyle }: { className?: string; svgStyle?: CSSProperties }) => {
	return (
		<div className={clsx(styles.circle, className)}>
			<svg
				viewBox="0 0 6 6"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				preserveAspectRatio="xMinYMax meet"
				style={svgStyle}
			>
				<circle cx="3" cy="3" r="2.5" stroke="currentColor" />
			</svg>
		</div>
	)
}

function Embellishments({
	progress = 0,
	className,
	animationFn = 'ease-in-out',
	animationTime = 2000,
	animationDisabled = false,
	startAnimation = true,
}: DecorationWrapperProps) {
	const [readyToUse, setReadyToUse] = useState(false)

	const getPercent = () => {
		if (progress >= 100) {
			return 100
		}
		if (progress <= 0) {
			return 0
		}
		return progress
	}

	const degrees = (getPercent() / 100) * 180
	const sizeChange = (getPercent() / 100) * 0.75

	const growingPlusAnimation = {
		transition: animationDisabled ? '' : `all ${animationTime}ms ${animationFn}`,
		transform: animationDisabled || !readyToUse ? '' : `rotate(${degrees}deg) scale(${sizeChange + 2})`,
	}

	const decreasingPlusAnimation = {
		transition: animationDisabled ? '' : `all ${animationTime}ms ${animationFn}`,
		transform: animationDisabled || !readyToUse ? '' : `rotate(${degrees}deg) scale(${1 - sizeChange})`,
	}

	const circleAnimation = {
		transition: animationDisabled ? '' : `all ${animationTime}ms ${animationFn}`,
		transform: animationDisabled || !readyToUse ? '' : 'rotateX(180deg) scale(1.1)',
	}

	useEffect(() => {
		if (getPercent() > 0 && startAnimation) {
			setReadyToUse(true)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startAnimation])

	return (
		<>
			<div
				className={clsx(styles.wrapper, className)}
				style={{
					transition: animationDisabled ? '' : `color ${animationTime / 10}ms ${animationFn}`,
					color: !readyToUse ? 'var(--color-grey-3)' : 'var(--color-red)',
				}}
			>
				<div className={styles.group1}>
					<PlusThick className={styles['plus-1']} svgStyle={growingPlusAnimation} />
					<PlusThin className={styles['plus-2']} svgStyle={decreasingPlusAnimation} />
					<Circle className={styles['circle-1']} svgStyle={circleAnimation} />
				</div>
				<div className={styles.group2}>
					<PlusThick className={styles['plus-3']} svgStyle={growingPlusAnimation} />
					<PlusThin className={styles['plus-4']} svgStyle={decreasingPlusAnimation} />
					<Circle className={styles['circle-2']} svgStyle={circleAnimation} />
				</div>
			</div>
		</>
	)
}

export default memo(Embellishments)
