/* eslint-disable @typescript-eslint/naming-convention */
import { memo } from 'react'

import { useWelcomeMat } from '~/components/hooks/useWelcomeMat'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import { useLocale } from '~/components/hooks/useLocale'
import { LocaleLink } from '~/components/primitives/LocaleLink/LocaleLink'
import DropMenu, { DropMenuOption } from '~/components/primitives/DropMenu/DropMenu'

import type { CountryUrlMap } from '~/lib/i18n/locale'
import { getCountryCodeByLocale, getNearbyCountries } from '~/lib/i18n/locale'

import styles from './CountrySelector.module.scss'

export interface Props {
	countrySites: CountryUrlMap
	requestedCountryCode?: string
}

const DEFAULT_COUNTRY_CODE = 'US'

export const CountrySelector = memo(function CountrySelector({ countrySites, requestedCountryCode }: Props) {
	const formatMessage = useFormatMessage()
	const locale = useLocale()
	const { welcomeMatData } = useWelcomeMat()

	const activeCountryCodeByLocale = getCountryCodeByLocale(locale)

	const nearbyCountries = getNearbyCountries(activeCountryCodeByLocale)

	let activeCountryCodeByRouter =
		requestedCountryCode ?? activeCountryCodeByLocale.toUpperCase() ?? DEFAULT_COUNTRY_CODE

	if (welcomeMatData) {
		activeCountryCodeByRouter = welcomeMatData.thisCountry
	}

	const buttonChildren = (
		<span className={styles.country__switcher}>
			<i
				data-testid="country-switcher-icon"
				className={`${styles['country__switcher-icon']} fflag fflag-${activeCountryCodeByRouter}`}
			/>
			{activeCountryCodeByRouter}
		</span>
	)

	const dropdownChildren = (handleClose: () => void) => (
		<>
			<DropMenuOption className={styles['country__options-header']}>
				<p>{formatMessage('locations-nearby')}</p>
			</DropMenuOption>

			{nearbyCountries?.map((country: string) => (
				<DropMenuOption key={country} data-testid={`nearby-country`}>
					<a href={countrySites[country].default} className={styles.country__links}>
						<i className={`${styles['country__switcher-icon']} fflag fflag-${country.toUpperCase()}`} />
						{formatMessage(`country-${country.toUpperCase()}`)}
					</a>
				</DropMenuOption>
			))}

			<DropMenuOption>
				<LocaleLink
					href="/change-location/"
					data-testid="see-all-link"
					onClick={handleClose}
					className={styles['country__see-all']}
				>
					{formatMessage('see-all')}
				</LocaleLink>
			</DropMenuOption>
		</>
	)

	return (
		<DropMenu buttonLabel={buttonChildren} name="country">
			{(handleClose) => dropdownChildren(handleClose)}
		</DropMenu>
	)
})
