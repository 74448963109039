import { Children, Fragment, isValidElement, useCallback, useEffect, useMemo, useRef } from 'react'
import clsx from 'clsx'

import type { IconColor } from '~/lib/icons'

import ArrowDropDownIcon from '~/components/primitives/icons/ArrowDropDownIcon'
import ArrowCloseDropDownIcon from '~/components/primitives/icons/ArrowCloseDropDownIcon'
import useToggle from '~/components/hooks/useToggle'

import variables from '~/styles/variables.module.scss'
import styles from './DropMenu.module.scss'

type DropMenuOptionProps = {
	children: React.ReactNode
	[x: string]: unknown
}

export const DropMenuOption = ({ children, ...props }: DropMenuOptionProps) => <li {...props}>{children}</li>

type DropMenuProps = {
	buttonLabel: React.ReactNode
	children:
		| React.ReactElement<DropMenuOptionProps>[]
		| React.ReactElement<DropMenuOptionProps>
		| ((
				setToggleActive: () => void,
		  ) => React.ReactElement<DropMenuOptionProps>[] | React.ReactElement<DropMenuOptionProps>)
	name: string
	onOpen?: () => void
	onClose?: () => void
}

function isDropMenuOption(element: React.ReactNode): element is React.ReactElement<DropMenuOptionProps> {
	return isValidElement(element) && (element.type === DropMenuOption || element.props.isChildOfDropMenu)
}

// Note! This function validates the children of the DropMenu component.
// It can be single DropMenuOption or Fragment array of DropMenuOption or function that return array of DropMenuOption.
function validateChildren(children): boolean {
	return Children.toArray(children).every((child) => {
		if (isDropMenuOption(child)) {
			return true
		}
		if (isValidElement(child) && child.type === Fragment) {
			return validateChildren(child.props.children)
		}
		return false
	})
}

const DropMenu = ({ buttonLabel, children, name, onOpen, onClose }: DropMenuProps) => {
	const { value: isActive, handleToggle, handleClose } = useToggle(false)

	const handleDropMenuToggle = useCallback(() => {
		if (!isActive && onOpen) onOpen()
		if (isActive && onClose) onClose()
		handleToggle()
	}, [handleToggle, isActive, onOpen, onClose])

	const handleDropMenuClose = useCallback(() => {
		onClose?.()
		handleClose()
	}, [handleClose, onClose])

	const dropdownAccessibilityOptions = useMemo(
		() => ({
			buttonId: `${name}-switcher`,
			dropdownId: `${name}-dropdown`,
			buttonDataTestId: `${name}-switcher`,
			dropdownDataTestId: `${name}-switcher-menu`,
			ariaControls: `${name}-dropdown`,
			iconDataTestId: `${name}-menu-icon`,
		}),
		[name],
	)

	const dropdownRef = useRef<HTMLUListElement | null>(null)

	// This useEffect is used to close the dropdown when clicking outside of it.
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
				handleDropMenuClose()
			}
		}

		if (isActive) {
			document.addEventListener('mousedown', handleClickOutside)
		} else {
			document.removeEventListener('mousedown', handleClickOutside)
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [isActive, handleDropMenuClose])

	if (!validateChildren(children)) {
		throw new Error('All children of DropMenu must be DropMenuOption components')
	}

	return (
		<div
			className={clsx(styles.dropdown, {
				[styles.dropdown__backdrop]: isActive,
			})}
		>
			<button
				type="button"
				className={styles.dropdown__button}
				id={dropdownAccessibilityOptions.buttonId}
				data-testid={dropdownAccessibilityOptions.buttonDataTestId}
				aria-controls={dropdownAccessibilityOptions.ariaControls}
				aria-haspopup="true"
				aria-expanded={isActive ? 'true' : 'false'}
				onClick={handleDropMenuToggle}
			>
				<span>{buttonLabel}</span>
				{isActive ? (
					<ArrowCloseDropDownIcon
						size="SM"
						color={variables.white as IconColor}
						data-testid={dropdownAccessibilityOptions.iconDataTestId}
					/>
				) : (
					<ArrowDropDownIcon
						size="SM"
						color={variables.white as IconColor}
						data-testid={dropdownAccessibilityOptions.iconDataTestId}
					/>
				)}
			</button>
			<ul
				ref={dropdownRef}
				className={isActive ? styles.dropdown__options : styles.dropdown__hidden}
				id={dropdownAccessibilityOptions.dropdownId}
				data-testid={dropdownAccessibilityOptions.dropdownDataTestId}
			>
				{typeof children === 'function' ? children(handleDropMenuClose) : children}
			</ul>
		</div>
	)
}

export default DropMenu
