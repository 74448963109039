import { useMemo } from 'react'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import { useFormatNumber } from '~/components/hooks/useFormatNumber'
import type { UserWithSession } from '~/lib/client-only/auth/types'
import { isNonNullish } from '~/types/strict-null-helpers'

type RegisteredGreetingProps = {
	customer: UserWithSession | undefined
	points?: number | undefined
	useTimedGreeting?: boolean
}

export const RegisteredGreeting = ({ customer, points, useTimedGreeting = false }: RegisteredGreetingProps) => {
	const formatMessage = useFormatMessage()
	const formatNumber = useFormatNumber()

	const message = useMemo(() => {
		if (!customer?.firstName) return formatMessage('welcome')
		if (useTimedGreeting) {
			const hours = new Date().getHours()
			if (hours >= 4 && hours < 12) return formatMessage('customer-morning', { name: customer?.firstName })
			if (hours >= 12 && hours < 18) return formatMessage('customer-afternoon', { name: customer?.firstName })
			if (hours >= 18 || (hours >= 0 && hours < 4))
				return formatMessage('customer-evening', { name: customer?.firstName })
		}
		return formatMessage('customer-welcome', { name: customer?.firstName })
	}, [customer?.firstName, formatMessage, useTimedGreeting])

	if (!customer || !customer.isRegistered) return <></>

	return (
		<>
			<span>{message}</span>
			{isNonNullish(points) && (
				<span>{formatMessage('loyalty-points-abbreviation', { points: formatNumber(points) })}</span>
			)}
		</>
	)
}
