import type { Category, ContentAsset } from '~/graphql/generated/uacapi/type-document-node'

export interface NavigationTree {
	name: string
	id: string
	url: string
	showMobile: boolean
	showDesktop: boolean
	parent?: string
	position?: number
	hideParent?: boolean
	flame?: boolean
	expand?: boolean
	shopAllVisible?: boolean
	banner?: ContentAsset
	disableClick?: boolean
	categories?: NavigationTree[]
	path?: string[] // This is a derived field that is not part of the data model
}

export interface NavigationTransport {
	tree: NavigationTree[] | null
}

export function convertCategoriesToNavigationTree(categories: Readonly<Category[] | Category>, applyFilters = true) {
	const catList = Array.isArray(categories) ? categories : [categories]

	return catList
		.filter((category) => !applyFilters || (category.showInMenu && category.isCategoryOnline))
		.sort((a, b) => (a.mobileCategoryPosition || 0) - (b.mobileCategoryPosition || 0))
		.map((category) => ({
			name: category.name,
			id: category.id,
			url: category.alternativeUrl || category.url || '',

			showMobile: category.showInMenu && !category.hideInMobileNavigation && !category.mobileHideParentCategory,
			showDesktop: category.showInMenu && !category.hideInDesktopNavigation,
			...(category.parent ? { parent: category.parent.id } : {}),
			...(category.mobileCategoryPosition ? { position: category.mobileCategoryPosition } : {}),
			...(category.mobileHideParentCategory ? { hideParent: category.mobileHideParentCategory } : {}),
			...(category.showFlameIcon ? { flame: category.showFlameIcon } : {}),
			...(category.expandMobileCategory ? { expand: category.expandMobileCategory } : {}),
			...(category.displayShopAllLinkInMobile ? { shopAllVisible: category.displayShopAllLinkInMobile } : {}),
			...(category.bannerAsset ? { banner: category.bannerAsset } : {}),
			...(category.disableClick ? { disableClick: category.disableClick } : {}),
			...(category.categories?.length > 0
				? { categories: convertCategoriesToNavigationTree(category.categories) }
				: {}),
		}))
}
