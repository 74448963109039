import MenuOutlinedIcon from '~/components/primitives/icons/MenuOutlinedIcon'
import type { IconColor } from '~/lib/icons'
import styles from './Navigation.module.scss'
import variables from '~/styles/variables.module.scss'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import { useNavbar } from '../NavbarProvider'
import { useAnalytics } from '~/components/hooks/useAnalytics'

export function HamburgerMenu() {
	const formatMessage = useFormatMessage()
	const { showMobileMenu, setShowMobileMenu } = useNavbar()
	const { analyticsManager } = useAnalytics()

	const onToggleMobileMenu = () => {
		setShowMobileMenu(!showMobileMenu)
		analyticsManager.fireClickedHamburgerMenu()
	}

	return (
		<div className={styles['nav__mobile-utility']}>
			<button
				type="button"
				className={styles['nav__toggle-menu']}
				aria-label={formatMessage('open-navigation')}
				onClick={onToggleMobileMenu}
			>
				<MenuOutlinedIcon color={variables.white as IconColor} />
			</button>
		</div>
	)
}
